import s from './SubBox.module.scss';
import { Form } from './Form/Form';
import StarRating from '../../Hero/icons/Stars';
import { usewpdataSelectors } from '../../../lib/wpdataState/selectors';

export const SubBox = () => {
  const { footer } = usewpdataSelectors();

  return (
    <div className={s.subBox}>
      <div>
        <h2 className={s.subBox__header}>{footer.newsletter.title}</h2>
        <p className={s.subBox__rating}>
          <StarRating rating={footer.newsletter.rating} className={s.stars} />
          <span>Verified Reviews</span>
        </p>
      </div>
      <div className={s.subBox__form}>
        <Form />
        <p className={s.subBox__submitText}>{footer.newsletter.textAfterSubscribeForm}</p>
      </div>
    </div>
  );
};
