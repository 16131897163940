type PlayIconProps = {
  className?: string;
};

const PlayIcon = ({ className = '' }: PlayIconProps) => {
  return (
    <svg className={className} width="100%" height="100%" viewBox="0 0 80 80" fill="none" style={{ display: 'block' }}>
      <circle opacity="0.7" cx="40" cy="40" r="40" fill="black"></circle>
      <path
        d="M31.25 25.5162C31.25 24.7389 32.098 24.2588 32.7645 24.6587L57.6188 39.5713C57.9424 39.7655 57.9424 40.2345 57.6188 40.4287L32.7645 55.3413C32.098 55.7412 31.25 55.2611 31.25 54.4838V25.5162Z"
        fill="white"
      ></path>
    </svg>
  );
};

export default PlayIcon;
