type ChevronRightProps = {
  className?: string;
};

export const DarkerStarIcon = ({ className }: ChevronRightProps) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 2179">
      <path
        id="Vector"
        d="M16.0754 5.71885L11.1567 4.96639L8.95208 0.263392C8.78741 -0.0877974 8.21208 -0.0877974 8.04741 0.263392L5.84342 4.96639L0.924765 5.71885C0.520766 5.78094 0.359433 6.27301 0.642766 6.56344L4.21609 10.2316L3.37143 15.4173C3.30409 15.8292 3.74342 16.1384 4.10676 15.9361L8.50008 13.5044L12.8934 15.9367C13.2534 16.137 13.6967 15.8339 13.6287 15.418L12.7841 10.2322L16.3574 6.56411C16.6407 6.27301 16.4787 5.78094 16.0754 5.71885Z"
        fill="#D1A748"
      />
    </g>
  </svg>
);
