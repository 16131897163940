import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import StarRating from './icons/Stars';
import c from './styles/ratingRow.module.scss';

type props = {
  style?: {
    [key: string]: string;
  };
};

const RatingRow = ({ style }: props) => {
  const s = style ? style : c;
  const { hero, Settings } = usewpdataSelectors();

  return (
    <div className={s.ratingRow}>
      <div className={s.avatars}>
        <div className={s.ratingsWrapper}>
          <div className={s.avatars_container}>
            <img className={s.avatars__img} src={`${Settings.root}/temp_images/avatars.png`} alt="url" />
          </div>
          <StarRating rating={hero.star_rating.rating} className={s.ratingStars} />
        </div>
      </div>
      <p style={{ color: hero?.styles.textColorSecondary }}>
        <span className={s.rating}>
          {`${hero.star_rating.rating_text} `}
          <span className={s.ratingText}>{hero.star_rating.rating}</span>
        </span>
        <div className={s.separator}></div>
        <div className={s.review}>{hero.star_rating.ratings_count_text}</div>
      </p>
    </div>
  );
};

export default RatingRow;
