import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductType, FetchedProductType } from '@/utils/types/productTypes';

type CheckoutSTate = {
  isPaypalSelected: boolean;
};

const initialState: CheckoutSTate = {
  isPaypalSelected: false,
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setIsPaypalSelected: (state, action: PayloadAction<boolean>) => {
      state.isPaypalSelected = action.payload;
    },
  },
});

export const { setIsPaypalSelected } = checkoutSlice.actions;

export default checkoutSlice.reducer;
