import React from 'react';
import './sectionTitle.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import StarRating from 'components/Hero/icons/Stars';

interface SectionTitleProps {
  heading: string;
  subheading?: string;
  description?: string;
  rating?: number;
  type: 'section' | 'fragment';
}

const SectionTitle: React.FC<SectionTitleProps> = ({ heading, subheading, description, rating, type = 'section' }) => {
  const { Theme } = usewpdataSelectors();

  return (
    <div className="section-title" data-type={type}>
      <h3 className="section-title--heading" data-theme={Theme.heading} style={{ color: Theme.headingColor }}>
        {heading}
      </h3>
      {subheading && (
        <div className="section-title--subheading__wrapper">
          {rating && (
            <div>
              <StarRating rating={rating} />
            </div>
          )}
          <h4 className="section-title--subheading" style={{ color: Theme.subheadingColor }}>
            {subheading}
          </h4>
        </div>
      )}
      <div className="section-title--divider"></div>
      {description && <p className="section-title--description">{description}</p>}
    </div>
  );
};

export default SectionTitle;
