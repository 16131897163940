type Props = {
  className?: string;
};

const Cross = ({ className }: Props) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.55264 0.24155C1.38267 0.0825742 1.15785 -0.00397364 0.92556 0.000140216C0.693268 0.00425408 0.471635 0.0987085 0.307354 0.263604C0.143073 0.4285 0.0489707 0.650962 0.0448722 0.884123C0.0407737 1.11728 0.126999 1.34294 0.285382 1.51355L4.73274 5.97755L0.285382 10.4416C0.197288 10.5239 0.12663 10.6233 0.0776225 10.7337C0.0286155 10.8441 0.00226374 10.9633 0.000139543 11.0841C-0.00198465 11.205 0.020162 11.325 0.0652585 11.4371C0.110355 11.5491 0.177477 11.6509 0.262621 11.7364C0.347765 11.8219 0.449187 11.8892 0.560835 11.9345C0.672483 11.9798 0.79207 12.002 0.912463 11.9999C1.03286 11.9977 1.15159 11.9713 1.26158 11.9221C1.37156 11.8729 1.47055 11.802 1.55264 11.7136L6 7.24955L10.4474 11.7136C10.5294 11.802 10.6284 11.8729 10.7384 11.9221C10.8484 11.9713 10.9671 11.9977 11.0875 11.9999C11.2079 12.002 11.3275 11.9798 11.4392 11.9345C11.5508 11.8892 11.6522 11.8219 11.7374 11.7364C11.8225 11.6509 11.8896 11.5491 11.9347 11.4371C11.9798 11.325 12.002 11.205 11.9999 11.0841C11.9977 10.9633 11.9714 10.8441 11.9224 10.7337C11.8734 10.6233 11.8027 10.5239 11.7146 10.4416L7.26726 5.97755L11.7146 1.51355C11.873 1.34294 11.9592 1.11728 11.9551 0.884123C11.951 0.650962 11.8569 0.4285 11.6926 0.263604C11.5284 0.0987085 11.3067 0.00425408 11.0744 0.000140216C10.8421 -0.00397364 10.6173 0.0825742 10.4474 0.24155L6 4.70555L1.55264 0.24155Z"
      fill="#1C2B54"
    />
  </svg>
);

export default Cross;
