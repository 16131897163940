type ArrowDownIconProps = {
  className?: string;
  fill?: string;
};

const ShippingIcon = ({ fill, className = '' }: ArrowDownIconProps) => {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fast-delivery (6) 1" clip-path="url(#clip0_366_160406)">
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.6094 5.01636C19.6902 5.49921 20.4861 6.71594 21.4841 8.39062H17.6094V5.01636ZM5.44897 8.53125C5.85638 8.53125 6.1521 8.72791 6.1521 9.1875C6.1521 9.57513 5.83844 9.88953 5.45099 9.89062H1.20312C0.814758 9.89062 0.5 10.2056 0.5 10.5938C0.5 10.9821 0.814758 11.2969 1.20312 11.2969H7.53125C7.92328 11.2969 8.23712 11.6115 8.23712 12C8.23712 12.3884 7.92236 12.7031 7.534 12.7031H1.20312C0.814758 12.7031 0.5 13.0179 0.5 13.4062C0.5 13.7946 0.814758 14.1094 1.20312 14.1094H2.75V16.2188C2.75 16.6071 3.06476 16.9219 3.45312 16.9219H4.86652C5.11847 18.163 6.21509 19.0781 7.50781 19.0781C8.80054 19.0781 9.89716 18.163 10.1491 16.9219H17.2884C17.5403 18.163 18.637 19.0781 19.9297 19.0781C21.2224 19.0781 22.319 18.163 22.571 16.9219H23.7969C24.1852 16.9219 24.5 16.6071 24.5 16.2188V12C24.5 9.93695 22.324 9.79999 22.322 9.79688H16.9062C16.5179 9.79688 16.2031 9.48212 16.2031 9.09375V4.875H3.45312C3.06476 4.875 2.75 5.18976 2.75 5.57812V7.125H1.90625C1.51788 7.125 1.20312 7.43976 1.20312 7.82812C1.20312 8.21649 1.51788 8.53125 1.90625 8.53125H5.44897ZM20.8412 15.4713C21.3445 15.9747 21.3445 16.7911 20.8412 17.2945C20.0315 18.1042 18.6406 17.5281 18.6406 16.3828C18.6406 15.2377 20.0315 14.6616 20.8412 15.4713ZM8.41931 15.4713C8.92267 15.9747 8.92267 16.7911 8.41931 17.2945C7.60962 18.1042 6.21875 17.5281 6.21875 16.3828C6.21875 15.2377 7.60962 14.6616 8.41931 15.4713Z"
          fill={fill ? fill : '#4F4948'}
        />
      </g>
      <defs>
        <clipPath id="clip0_366_160406">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShippingIcon;
