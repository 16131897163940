import { useEffect } from 'react';
import s from './QuantityCards.module.scss';
import { productType } from 'lib/productsState/productsTypes';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import { useCartActions } from 'lib/cartState/actions';
import { useCartSelectors } from 'lib/cartState/selectors';
import QuantityCard from './QuantityCard';
import SelectCard from './SelectCard';
import { Divider } from '@mantine/core';
import Button from 'components/Button/CTA/Button';

const QuantityCards = ({ product }: { product: productType[] }) => {
  const { Settings } = usewpdataSelectors();
  const { hero } = usewpdataSelectors();
  const { pickerUpsells, EnableColorVariants, EnableSizeVariants, ProductColorVariants, ProductSizeVariants } =
    useproductsdataSelectors();
  const {
    setSelectedProductAction,
    setDefaultVariantsAction,
    changeBundlePriceAction,
    changeBundleProduct,
    adjustMainProductQuantityAction,
  } = useCartActions();
  const { cartProducts } = useCartSelectors();
  useEffect(() => {
    const defaultProduct = product.find((item) => item.status.toLowerCase() === 'best seller');
    setSelectedProductAction(defaultProduct);

    pickerUpsells &&
      pickerUpsells.forEach((upsell) => {
        const default_option = upsell.products_list.find((item) => item.default_option === true);
        changeBundlePriceAction({
          id: default_option?.id,
          parent_id: upsell.id,
          sku: default_option?.sku,
          label: default_option?.tr_title,
          price: default_option?.price,
          old_price: default_option?.old_price,
          konnektive_id: default_option?.konnektive_id,
        });
      });
    if (EnableColorVariants || EnableSizeVariants) {
      setDefaultVariantsAction(5, ProductColorVariants, ProductSizeVariants);
      // setDefaultVariantsAction(defaultAmountOfVariants, ProductColorVariants, ProductSizeVariants);
    }
  }, []);
  const handleClick = (id) => {
    const selectedProduct = product.filter((item) => {
      return item.id === id;
    });
    setSelectedProductAction(...selectedProduct);
  };

  const dividerStyles = {
    root: {
      height: 'fit-content',
    },
    label: {
      color: hero.styles.textColorPrimary,
      fontWeight: 600,
    },
  };

  const handleUpsellClick = ({
    id,
    parent_id,
    sku,
    label,
    price,
    old_price,
    konnektive_id,
  }: {
    id: string;
    parent_id: string;
    sku: string;
    label: string;
    price: number;
    old_price: number;
    konnektive_id: string;
  }): void => {
    changeBundleProduct(sku, label, id, parent_id, price, old_price, konnektive_id);
  };

  return (
    <section className={s.quantity}>
      <div className={s.quantity__cardList}>
        {product &&
          product.map((item: productType) => {
            const active = cartProducts?.mainProduct.product.id === item.id;
            return (
              <QuantityCard
                colorVariants={EnableColorVariants}
                sizeVariants={EnableSizeVariants}
                active={active}
                id={item.id}
                key={item.id}
                amount={item.amount}
                status={item.status}
                display_name={item.display_name}
                price={item.price}
                image={item.image.sizes?.thumbnail}
                tr_additional_texts={item.tr_additional_texts}
                old_price={item.old_price}
                handleClick={(id: string) => {
                  handleClick(id);
                }}
              />
            );
          })}
        {pickerUpsells &&
          pickerUpsells.map((upsell) => {
            if (upsell.upsell_layout === 'dropdown') {
              return (
                <>
                  <Divider
                    label={upsell.tr_divider_text}
                    labelPosition="center"
                    color="#E5E5E5"
                    classNames={{ label: s.dividerText }}
                    styles={dividerStyles}
                  />

                  <SelectCard
                    options={upsell?.products_list}
                    handleClick={(e) => {
                      handleUpsellClick({ parent_id: upsell?.id, ...e });
                    }}
                  />
                </>
              );
            } else {
              return (
                <>
                  <Divider
                    label={upsell.tr_divider_text}
                    labelPosition="center"
                    color="#E5E5E5"
                    classNames={{ label: s.dividerText }}
                    styles={dividerStyles}
                  />
                  {upsell?.products_list &&
                    upsell?.products_list.map((item, index) => {
                      const active = cartProducts?.mainProduct?.bundle.some((cart) => {
                        return cart.id === item.id;
                      });
                      return (
                        <QuantityCard
                          active={active}
                          id={item.id}
                          key={index}
                          amount={1}
                          display_name={item.tr_title}
                          price={item.price}
                          image={item.image}
                          old_price={item.old_price}
                          handleClick={(id) => {
                            handleUpsellClick({
                              parent_id: upsell.id,
                              id: id,
                              sku: item.sku,
                              label: item.tr_title,
                              price: item.price,
                              old_price: item.old_price,
                              konnektive_id: item.konnektive_id,
                            });
                          }}
                        />
                      );
                    })}
                </>
              );
            }
          })}
      </div>
      <div className={s.ctaWrapper}>
        <div
          className={s.ctaWrapper__incrementer}
          style={Settings.bundleMode ? { display: 'flex' } : { display: 'none' }}
        >
          <button
            style={{
              color: cartProducts.mainProduct.product.amount == 1 ? '#E5E5E5' : hero.styles.textColorPrimary,
            }}
            onClick={() => {
              const qty = Number(cartProducts.mainProduct.product.amount) - 1;
              if (qty === 0) return;
              adjustMainProductQuantityAction(qty);
            }}
          >
            -
          </button>
          <div style={{ color: hero.styles.textColorPrimary }}>
            {cartProducts.mainProduct.product.amount && cartProducts.mainProduct.product.amount}
          </div>
          <button
            style={{ color: hero.styles.textColorPrimary }}
            onClick={() => {
              const qty = Number(cartProducts.mainProduct.product.amount) + 1;
              adjustMainProductQuantityAction(qty);
            }}
          >
            {' '}
            +{' '}
          </button>
        </div>
        <Button text={hero?.ctaButtonText} className={s.ctaButton} />
      </div>
    </section>
  );
};
export default QuantityCards;
