import { useCartActions } from 'lib/cartState/actions';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './Button.module.scss';

const Button = ({ text, className, attribute }: CTAProps) => {
  const { Theme, Settings, Layout } = usewpdataSelectors();

  const { toggleCartAction } = useCartActions();
  const isLink = Layout.hero.layout === 'lander';

  if (isLink) {
    return (
      <a
        data-cy={attribute}
        href={Settings.link}
        data-theme={Theme?.button}
        className={`${className} cta ${s.cta}`}
        style={{
          '--button-custom-background': Theme?.customButton?.background,
          '--button-custom-backgroundHover': Theme?.customButton?.backgroudHover,
          '--button-custom-borderColor': Theme?.customButton?.borderColor,
          '--button-custom-textColor': Theme?.customButton?.textColor,
        }}
      >
        {text}
      </a>
    );
  }

  return (
    <button
      data-cy={attribute}
      onClick={toggleCartAction}
      data-theme={Theme?.button}
      className={`${className} cta ${s.cta}`}
      style={{
        '--button-custom-background': Theme?.customButton?.background,
        '--button-custom-backgroundHover': Theme?.customButton?.backgroudHover,
        '--button-custom-borderColor': Theme?.customButton?.borderColor,
        '--button-custom-textColor': Theme?.customButton?.textColor,
      }}
    >
      {text}
    </button>
  );
};

export default Button;
