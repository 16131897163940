type CheckmarkIconProps = {
  className?: string;
};

const CheckmarkIcon = ({ className = '' }: CheckmarkIconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <g clipPath="url(#clip0_4124_19981)">
        <path
          d="M24 0C10.7452 0 0 10.7452 0 24C0 37.2555 10.7452 48 24 48C37.2555 48 48 37.2555 48 24C48 10.7452 37.2555 0 24 0ZM24 45.0472C12.4207 45.0472 3 35.5793 3 23.9999C3 12.4207 12.4207 2.99991 24 2.99991C35.5793 2.99991 45 12.4207 45 23.9999C45 35.5791 35.5793 45.0472 24 45.0472ZM33.5783 15.2183L19.497 29.388L13.1557 23.0467C12.57 22.461 11.6205 22.461 11.034 23.0467C10.4482 23.6325 10.4482 24.582 11.034 25.1677L18.4582 32.5928C19.044 33.1777 19.9935 33.1777 20.58 32.5928C20.6475 32.5252 20.7052 32.4517 20.7577 32.3753L35.7008 17.34C36.2858 16.7542 36.2858 15.8047 35.7008 15.2183C35.1143 14.6325 34.1647 14.6325 33.5783 15.2183Z"
          fill="#6B5F5F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4124_19981">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckmarkIcon;
