type StarTypes = 'full' | 'empty' | 'error';

type StarPropsType = {
  type: StarTypes;
  onRate: () => void;
  onHoverIn: () => void;
  onHoverOut: () => void;
  emptyStarStroke: string;
  emptyStarFill: string;
  fullStarStroke: string;
  fullStarFill: string;
  errorStarStroke: string;
  errorStarFill: string;
};

const Star = ({
  onRate,
  type,
  onHoverIn,
  onHoverOut,
  emptyStarStroke,
  emptyStarFill,
  fullStarStroke,
  fullStarFill,
  errorStarStroke,
  errorStarFill,
}: StarPropsType) => {
  const starStyle = {
    display: 'block',
    cursor: 'pointer',
  };

  return (
    <span role="button" style={starStyle} onClick={onRate} onMouseEnter={onHoverIn} onMouseLeave={onHoverOut}>
      {type === 'full' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.3631 8.57827L15.9851 7.44959L12.6781 0.395088C12.4311 -0.131696 11.5681 -0.131696 11.3211 0.395088L8.01513 7.44959L0.637148 8.57827C0.0311491 8.67141 -0.21085 9.40951 0.214149 9.84516L5.57413 15.3474L4.30714 23.1259C4.20614 23.7439 4.86514 24.2075 5.41013 23.9041L12.0001 20.2567L18.5901 23.9051C19.1301 24.2055 19.7951 23.7509 19.6931 23.1269L18.4261 15.3484L23.7861 9.84616C24.2111 9.40951 23.9681 8.67141 23.3631 8.57827Z"
            fill={fullStarFill}
            stroke={fullStarStroke}
          />
        </svg>
      )}
      {type === 'empty' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.3631 8.57827L15.9851 7.44959L12.6781 0.395088C12.4311 -0.131696 11.5681 -0.131696 11.3211 0.395088L8.01513 7.44959L0.637148 8.57827C0.0311491 8.67141 -0.21085 9.40951 0.214149 9.84516L5.57413 15.3474L4.30714 23.1259C4.20614 23.7439 4.86514 24.2075 5.41013 23.9041L12.0001 20.2567L18.5901 23.9051C19.1301 24.2055 19.7951 23.7509 19.6931 23.1269L18.4261 15.3484L23.7861 9.84616C24.2111 9.40951 23.9681 8.67141 23.3631 8.57827Z"
            stroke={emptyStarStroke}
            fill={emptyStarFill}
          />
        </svg>
      )}
      {type === 'error' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.3631 8.57827L15.9851 7.44959L12.6781 0.395088C12.4311 -0.131696 11.5681 -0.131696 11.3211 0.395088L8.01513 7.44959L0.637148 8.57827C0.0311491 8.67141 -0.21085 9.40951 0.214149 9.84516L5.57413 15.3474L4.30714 23.1259C4.20614 23.7439 4.86514 24.2075 5.41013 23.9041L12.0001 20.2567L18.5901 23.9051C19.1301 24.2055 19.7951 23.7509 19.6931 23.1269L18.4261 15.3484L23.7861 9.84616C24.2111 9.40951 23.9681 8.67141 23.3631 8.57827Z"
            stroke={errorStarStroke}
            fill={errorStarFill}
          />
        </svg>
      )}
    </span>
  );
};

export default Star;
