import { CloseIcon } from '../../SharedIcons/CloseIcon';

import CheckmarkIcon from './Icons/CheckmarkIcon';
import s from './SuccessModal.module.scss';

type SuccessModalProps = {
  onClose: () => void;
};

export const SuccessModal = ({ onClose }: SuccessModalProps) => {
  return (
    <div className={s.successModal}>
      <div className={s.successModal__checkmarkIcon}>
        <CheckmarkIcon />
      </div>
      <span>Thank you! Your review has been submitted and will appear once approved.</span>
      <a className={s.successModal__closeButton} onClick={onClose}>
        <CloseIcon />
      </a>
    </div>
  );
};
