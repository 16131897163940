import s from './boastBlock.module.scss';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import { DarkerStarIcon } from './icons/darkerStarIcon';

const QuoteBlock = () => {
  const { Award } = usewpdataSelectors();
  return (
    <div className={s.quoteBlock}>
      <img src={Award.awardImage} alt="alt" className={s.quoteBlock__badge} />
      <div className={s.quoteBlock__middleBlock}>
        <div className={s.quoteBlock__rating}>
          {Array.from({ length: 5 }, (_, i) => (
            <DarkerStarIcon key={i} />
          ))}
        </div>
        <p className={s.quoteBlock__title}>"{Award.title}"</p>
        <p className={s.quoteBlock__subtitle}>{Award.subtitle}</p>
      </div>
      <img src={Award.image} alt="alt" className={s.quoteBlock__productImg} />
    </div>
  );
};

export default QuoteBlock;
