import { Progress } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './ItemsLeftBar.module.scss';

const ItemsLeftBar = () => {
  const MAX_ITEMS = 67;
  const [itemsLeft, setItemsLeft] = useState<number>(58);
  const [progressValue, setProgressValue] = useState<number>(75);

  useEffect(() => {
    const decrementRandomly = () => {
      const decrement = Math.floor(Math.random() * 8) + 1;
      setItemsLeft((prevValue) => Math.max(prevValue - decrement, 0));
    };

    const setRandomInterval = () => {
      const randomInterval = Math.floor(Math.random() * 5000) + 15000;

      const timeoutId = setTimeout(() => {
        decrementRandomly();
        setRandomInterval();
      }, randomInterval);

      return () => clearTimeout(timeoutId);
    };

    const cleanup = setRandomInterval();
    return cleanup;
  }, []);

  useEffect(() => {
    const calculateProgress = (items: number) => {
      return (items / MAX_ITEMS) * 100;
    };

    setProgressValue(calculateProgress(itemsLeft));
  }, [itemsLeft]);

  const { hero } = usewpdataSelectors();

  return (
    <div className={s.itemsLeft}>
      <p className={s.label} style={{ color: hero.styles.textColorSecondary }}>
        {hero.availability_label} | {itemsLeft} {hero.left_label}
      </p>
      <Progress
        value={progressValue}
        transitionDuration={4000}
        classNames={{ root: s.bar_root, section: s.bar_section }}
      />
    </div>
  );
};

export default ItemsLeftBar;
