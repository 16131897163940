import { Formik, Field, useField, useFormikContext, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import StarRating from '../../StarRating/StarRating';
import { ReviewModalInput } from './ReviewModalInput';
import { ReviewModalInputImage } from './ReviewModalInputImages';
import ErrorBadge from './ErrorBadge';
import { CtaButton } from '../../Button/CtaButton/CtaButton';
import s from './ReviewModal.module.scss';

export type ReviewModalFieldNames = 'name' | 'title' | 'review' | 'images';

export type ReviewModalImage = {
  id: string;
  url: string;
};

export type ReviewModalFormFields = {
  rating: number;
  name: string;
  title: string;
  review: string;
  images: ReviewModalImage[];
  picked: string;
};

type StarRatingFieldProps = {
  name: string;
};

const initialValues = {
  rating: 0,
  name: '',
  title: '',
  subject: '',
  review: '',
  images: [],
  picked: 'yes',
};

export const reviewModalValidationSchema = Yup.object().shape({
  rating: Yup.number().min(1).required('Required'),
  name: Yup.string().required('Required'),
  title: Yup.string().required('Required'),
  review: Yup.string().required('Required'),
});

const StarRatingField = ({ name }: StarRatingFieldProps) => {
  const [field, , helpers] = useField(name);
  const { errors, touched } = useFormikContext<ReviewModalFormFields>();

  const isErrors = errors.rating && touched.rating;

  return <StarRating rating={field.value} setRating={(value) => helpers.setValue(value)} isError={isErrors || false} />;
};

type ReviewModalProps = {
  onSubmit: () => void;
};

const radioInputs = [
  { name: 'Results', options: ['Excellent', 'Good', 'Poor'] },
  { name: 'Quality', options: ['Excellent', 'Good', 'Poor'] },
  { name: 'Satisfaction', options: ['Excellent', 'Good', 'Poor'] },
];

const createRadioInputs = (title: string, variants: { name: string; options: string[] }[]) => (
  <div className={s.reviewModal__inputRadio}>
    <label className={s.reviewModal__label}>{title}</label>
    <div className={s.reviewModal__radioGroupWithTitle} role="group" aria-labelledby="review-modal-radio-group">
      {variants.map((variant) => (
        <div className={s.reviewModal__radioInputGroup}>
          <p>{variant.name}:</p>
          <div className={s.reviewModal__inputOptions}>
            {variant.options.map((option) => (
              <label className={s.reviewModal__radioButtonLabel}>
                <Field type="radio" name={variant.name} value={option} />
                {option}
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export const ReviewModal = ({ onSubmit }: ReviewModalProps) => {
  return (
    <div className={s.reviewModal}>
      <Formik
        initialValues={initialValues}
        validationSchema={reviewModalValidationSchema}
        onSubmit={(values: ReviewModalFormFields) => {
          onSubmit();
        }}
      >
        {({ errors, touched, values }) => {
          const isNameTyped = errors.name && touched.name;
          const isTitleTyped = errors.title && touched.title;
          const isReviewTyped = errors.review && touched.review;
          return (
            <FormikForm>
              <section className={s.reviewModal__inputBoxWrapper}>
                <div className={s.reviewModal__inputStars}>
                  <label className={s.reviewModal__label}>What would you rate this product?</label>
                  <StarRatingField name="rating" />
                </div>
                <ReviewModalInput
                  input="name"
                  label="Name"
                  placeholder="Enter your name"
                  values={values}
                  errors={errors}
                  isTyped={isNameTyped}
                />
                <ReviewModalInput
                  input="title"
                  label="Review title"
                  placeholder="Enter review title"
                  values={values}
                  errors={errors}
                  isTyped={isTitleTyped}
                />
                <ReviewModalInput
                  input="review"
                  label="Review"
                  placeholder="Write your comment here"
                  values={values}
                  errors={errors}
                  isTyped={isReviewTyped}
                  isTextArea
                />
                <ReviewModalInputImage input="images" label="Picture (optional)" values={values} />
                <div className={s.reviewModal__inputRadio__wrapper}>
                  <div className={s.reviewModal__inputRadio}>
                    <label className={s.reviewModal__label}>Do you recommend this product?</label>
                    <div className={s.reviewModal__radioGroup} role="group" aria-labelledby="review-modal-radio-group">
                      <label className={s.reviewModal__radioButtonLabel}>
                        <Field type="radio" name="picked" value="yes" />
                        Yes
                      </label>
                      <label className={s.reviewModal__radioButtonLabel}>
                        <Field type="radio" name="picked" value="no" />
                        No
                      </label>
                    </div>
                  </div>
                  {createRadioInputs('How you rate the features of this product?', radioInputs)}
                </div>
                {Object.keys(errors).length > 0 && <ErrorBadge />}
              </section>
              <div className={s.reviewModal__btnWrapper}>
                <button type="submit">test</button>
                <CtaButton className={s.reviewModal__submitBtn} text="SUBMIT REVIEW" type="submit" />
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
};
