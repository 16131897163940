import { Check } from './icons/Check';
import s from './UpsellModal.module.scss';

const Benefits = () => {
  const benefits = {
    title: 'Your Top Rated Companion for Safety, Security, and Peace of Mind',
    list: [
      'High-quality video recording',
      'Enhances safety & security',
      'Constant monitoring',
      'User-friendly & versatile',
    ],
  };

  return (
    <div className={s.benefits}>
      <div className={s.title}>{benefits.title}</div>
      <ul className={s.list}>
        {benefits.list.map((benefit) => (
          <li>
            <Check />
            {benefit}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Benefits;
