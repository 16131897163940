type PropType = {
  className?: string;
  color?: string;
};

export default function Like({ className, color = '53A179' }: PropType) {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4550_106946)">
        <path
          d="M0.359867 5.27246C0.163812 5.27246 0.00488281 5.43141 0.00488281 5.62745V10.8413C0.00488281 11.0374 0.163836 11.1963 0.359867 11.1963H2.49861V5.27246H0.359867Z"
          fill={`#${color}`}
        />
        <path
          d="M11.9952 7.23252C11.9952 6.78189 11.7531 6.38673 11.3921 6.17031C11.5304 5.9706 11.6115 5.72833 11.6115 5.46754C11.6115 4.7852 11.0564 4.23006 10.374 4.23006H7.76171C7.84177 3.86724 7.95128 3.32281 8.02752 2.75288C8.22594 1.26973 8.09033 0.44719 7.60079 0.164322C7.29559 -0.0119984 6.96652 -0.0477641 6.67421 0.0635174C6.44839 0.149533 6.14342 0.36101 5.96963 0.880035L5.28305 2.67772C4.93498 3.53734 3.86906 4.4398 3.20801 4.93881V11.3531C4.43318 11.7824 5.71165 12 7.01595 12H9.86235C10.5447 12 11.0998 11.4449 11.0998 10.7625C11.0998 10.5236 11.0317 10.3002 10.914 10.1109C11.3265 9.90998 11.6114 9.48637 11.6114 8.99753C11.6114 8.73672 11.5303 8.49449 11.392 8.29476C11.7531 8.07834 11.9952 7.68316 11.9952 7.23252Z"
          fill={`#${color}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_4550_106946">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
