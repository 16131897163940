import fullStar from './fullStar.svg';
import emptyStar from './emptyStar.svg';
import halfStar from './halfStar.svg';

interface StarRatingProps {
  rating: number;
  roundUp?: boolean;
  className?: CSSModuleClasses[string];
}

const StarRating = ({ rating, roundUp = false, className }: StarRatingProps) => {
  const MAX_STARS = 5;
  const fullStars = roundUp ? Math.ceil(rating) : Math.floor(rating);
  const hasHalfStar = roundUp ? false : rating % 1 >= 0.5;
  const emptyStars = MAX_STARS - fullStars - (hasHalfStar ? 1 : 0);

  const starsArray = [];

  for (let i = 0; i < fullStars; i++) {
    starsArray.push(<img key={`full-${i}`} src={fullStar} alt="Full Star" />);
  }

  if (hasHalfStar) {
    starsArray.push(<img key="half" src={halfStar} alt="Half Full Star" />);
  }

  for (let i = 0; i < emptyStars; i++) {
    starsArray.push(<img key={`empty-${i}`} src={emptyStar} alt="Empty Star" />);
  }

  return <div className={className}>{starsArray}</div>;
};

export default StarRating;
