import s from './ErrorBadge.module.scss';

const ErrorBadge = () => {
  return (
    <div className={s.errorBadge}>
      <p className={s.errorBadge__text}>Not all the fields have beed filled out correctly!</p>
    </div>
  );
};
export default ErrorBadge;
