import { productType, VariantType, upsellItem } from 'lib/productsState/productsTypes';
import s from './Upsells.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useCartActions } from 'lib/cartState/actions';
import { v4 as uuidv4 } from 'uuid';
import StarRating from 'components/Hero/icons/Stars';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import BundleVariantPicker from './BundleVariantPicker';
import { useEffect, useState } from 'react';

const BundleUpsell = ({ product }: { product: productType }) => {
  const { Settings, Cart, Theme } = usewpdataSelectors();
  const { setUpsellModalAction, addUpsellToCartAction } = useCartActions();
  const { pickerUpsells } = useproductsdataSelectors();
  const [selectedVariants, setSelectedVariants] = useState<{ productId: string; bundleOptionId: string }[]>([]);

  const filterPickerUpsells = () => {
    return pickerUpsells.map((pickerUpsell) => {
      const selectedVariant = selectedVariants.find((variant) => variant.productId === pickerUpsell.id);

      if (selectedVariant) {
        const matchedProduct = pickerUpsell.products_list.find(
          (product) => product.id === selectedVariant.bundleOptionId
        );

        if (matchedProduct) {
          return {
            ...pickerUpsell,
            products_list: [matchedProduct],
          };
        }
      }
      return pickerUpsell;
    });
  };

  const handleAddToCart = () => {
    const variants = filterPickerUpsells();
    addUpsellToCartAction({
      product: { ...product, type: 'bundle' },
      variant: variants,
    });
  };

  useEffect(() => {
    const updatedVariants = [...selectedVariants];

    pickerUpsells.forEach((bundle) => {
      const defaultBundleOption = bundle.products_list.find((variant) => variant.default_option === true);

      if (defaultBundleOption) {
        updatedVariants.push({ productId: bundle.id, bundleOptionId: defaultBundleOption.id });
      } else {
        updatedVariants.push({ productId: bundle.id, bundleOptionId: bundle.products_list[0].id });
      }
    });
    const splicedCopy = updatedVariants.slice(0, pickerUpsells.length);

    setSelectedVariants(splicedCopy);
  }, [pickerUpsells]);

  const setVariant = (productId: string, bundleId: string) => {
    const updatedVariants = selectedVariants.map((variant) =>
      variant.productId === productId ? { ...variant, bundleOptionId: bundleId } : variant
    );

    setSelectedVariants(updatedVariants);
  };

  return (
    <div className={s.upsellCard}>
      <div className={s.imgWrapper}>
        <img src={product.image.url} alt={product.image.alt} />
        <div className={s.badge} style={{ background: Cart.styles.badgeColor }}>
          <p>{Cart.bestSellerLabel}</p>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.upper}>
          <div className={s.title} data-theme={Theme.heading} style={{ color: Cart.styles.colorPrimary }}>
            {product.display_name}
          </div>
          <div className={s.rating}>
            <StarRating rating={5} className={s.stars} />
            <p style={{ color: Cart.styles.colorSecondary }}>1356 Verified Reviews</p>
          </div>
          <div className={s.prices}>
            <div className={s.price} style={{ color: Cart.styles.colorPrimary }}>
              {Settings.formattedString.replace(/(0\.00|0,00|0)/, product.price.toString())}
            </div>
            <div className={s.oldPrice} style={{ color: Cart.styles.colorSecondary }}>
              {Settings.formattedString.replace(/(0\.00|0,00|0)/, product.old_price.toString())}
            </div>
          </div>
        </div>
        <div className={s.bundleVariantPickers}>
          {pickerUpsells.map((variant: any, index: number) => {
            return (
              <BundleVariantPicker
                bundleUpsell={variant}
                key={index}
                handleClick={setVariant}
                selectedVariants={selectedVariants}
              />
            );
          })}
        </div>
        <div className={s.lower}>
          <button className={s.viewBtn} data-theme={Theme.button}>
            {Cart.viewLabel}
          </button>
          <button className={`${s.addBtn} cta`} data-theme={Theme.button} onClick={() => handleAddToCart()}>
            {Cart.addLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BundleUpsell;
