import Benefits from './Benefits';
import CtaButton from './CtaButton';
import s from './styles/hero.module.scss';
import HeroTitles from './HeroTitles';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

const Hero = () => {
  const { hero } = usewpdataSelectors();
  return (
    <div className={s.hero__wrapper} style={{ background: hero?.styles?.background }}>
      <div className={s.hero}>
        <span className={s.hero__1st}>
          <HeroTitles />
        </span>
        <div className={s.hero__3rd}>
          <Benefits />
          <CtaButton />
        </div>
        {hero.hero_image && <img className={s.hero__img} src={hero.hero_image} alt="alt" />}
      </div>
    </div>
  );
};

export default Hero;
