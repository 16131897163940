import React from 'react';
import Verified from '../../../public/images/icons/verified.svg';
import { BaComparisonCard } from '../../lib/wpdataState/wpDataType';

const ComparisonCard = ({ item, primaryColor }: BaComparisonCard) => {
  const { product_image, rating, tr_description, tr_nameDuration } = item;
  return (
    <div className="ba-comparison-card">
      <img src={product_image} alt="alt" className="ba-comparison-card__image mon-img-700-w" />
      <div className="ba-comparison-card__content">
        <img className="ba-comparison-card__score" src={`${window.template_root}/stars/${rating}.svg`} alt="alt" />
        <div className="ba-comparison-text--paragraph" style={{ color: primaryColor }}>
          "{tr_description}"
        </div>
        <div className="ba-comparison-card__user">
          <span className="ba-comparison-text--user" style={{ color: primaryColor }}>
            {tr_nameDuration}
          </span>
          <img className="ba-comparison-card__verifiedIcon" src={Verified} alt="alt" />
          <span className="ba-comparison-text--verified"> Verified</span>
        </div>
      </div>
    </div>
  );
};

export default ComparisonCard;
