import React from 'react';
import Layout from 'Layout';
import { Provider } from 'react-redux';
import store from 'lib/store';
import './index.css';
import ReactDOM from 'react-dom/client';
import './styles/styles.scss';
import '@mantine/core/styles.css';
import { MantineProvider, createTheme } from '@mantine/core';

const theme = createTheme({
  breakpoints: {
    sm: '0em',
    md: '48em',
    lg: '72.9375em',
    xl: '90em',
  },
});

ReactDOM.createRoot(document.getElementById('react-app')!).render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <Provider store={store}>
        <Layout />
      </Provider>
    </MantineProvider>
  </React.StrictMode>
);
