type Props = {
  className?: string;
};

export const ChevronDown = ({ className }: Props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="down 2">
        <g id="16">
          <path
            id="Vector"
            d="M11.9997 16.4269C11.8681 16.4277 11.7376 16.4025 11.6158 16.3527C11.494 16.3029 11.3831 16.2296 11.2897 16.1369L5.2897 10.1369C5.10139 9.94864 4.99561 9.69324 4.99561 9.42694C4.99561 9.16064 5.10139 8.90525 5.2897 8.71694C5.478 8.52864 5.7334 8.42285 5.9997 8.42285C6.266 8.42285 6.52139 8.52864 6.7097 8.71694L11.9997 14.0169L17.2897 8.72694C17.481 8.56312 17.7271 8.47751 17.9787 8.48723C18.2304 8.49695 18.4692 8.60129 18.6473 8.77938C18.8254 8.95747 18.9297 9.19621 18.9394 9.44789C18.9491 9.69957 18.8635 9.94564 18.6997 10.1369L12.6997 16.1369C12.5134 16.3217 12.262 16.4258 11.9997 16.4269Z"
            fill="#404040"
          />
        </g>
      </g>
    </svg>
  );
};
