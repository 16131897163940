type StarProps = {
  className?: string;
  color?: string;
};

const Star = ({ className, color = '#FF9213' }: StarProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M11.6815 4.28914L7.99255 3.7248L6.33906 0.197544C6.21556 -0.0658481 5.78406 -0.0658481 5.66056 0.197544L4.00756 3.7248L0.318574 4.28914C0.0155746 4.33571 -0.105425 4.70476 0.107074 4.92258L2.78707 7.67368L2.15357 11.563C2.10307 11.8719 2.43257 12.1038 2.70507 11.952L6.00006 10.1283L9.29505 11.9525C9.56505 12.1028 9.89755 11.8754 9.84655 11.5635L9.21305 7.67418L11.893 4.92308C12.1055 4.70476 11.984 4.33571 11.6815 4.28914Z"
        fill={color}
      />
    </svg>
  );
};

export default Star;
