type AudioOffIconProps = {
  className?: string;
};

const AudioOffIcon = ({ className = '' }: AudioOffIconProps) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 32 32" fill="none" style={{ display: 'block' }}>
      <path
        d="M2 10H7.81818L17 3V29L7.81818 22H2V10ZM8.49361 12L15 7.03968V24.9603L8.49361 20H4V12H8.49361Z"
        fill="white"
      ></path>
      <path
        d="M20.8219 10.4325C20.4314 10.042 19.7982 10.042 19.4077 10.4325C19.0172 10.8231 19.0172 11.4562 19.4077 11.8468L23.643 16.0821L19.4077 20.3174C19.0172 20.708 19.0172 21.3411 19.4077 21.7316C19.7982 22.1222 20.4314 22.1222 20.8219 21.7316L25.0573 17.4963L29.2926 21.7316C29.6831 22.1222 30.3163 22.1222 30.7068 21.7316C31.0973 21.3411 31.0973 20.708 30.7068 20.3174L26.4715 16.0821L30.7068 11.8468C31.0973 11.4562 31.0973 10.8231 30.7068 10.4325C30.3163 10.042 29.6831 10.042 29.2926 10.4325L25.0573 14.6679L20.8219 10.4325Z"
        fill="white"
      ></path>
    </svg>
  );
};

export default AudioOffIcon;
