import s from './RightsBox.module.scss';
import { PayPal } from '../../SharedIcons/CreditCards/Paypal';
import { Visa } from '../../SharedIcons/CreditCards/Visa';
import { Mastercard } from '../../SharedIcons/CreditCards/Mastercard';
import { Amex } from '../../SharedIcons/CreditCards/Amex';
import { DinersClub } from '../../SharedIcons/CreditCards/DinersClub';
import { Discover } from '../../SharedIcons/CreditCards/Discover';
import { usewpdataSelectors } from '../../../lib/wpdataState/selectors';

export const RightsBox = () => {
  const { footer } = usewpdataSelectors();
  return (
    <div className={s.rightsBox}>
      <div className={s.rightsBox__payment}>
        <PayPal />
        <Visa />
        <Mastercard />
        <Amex />
        <DinersClub />
        <Discover />
      </div>

      <p className={s.rightsBox__rights}>{`© ${new Date().getFullYear()} ${footer.copyText}`}</p>
    </div>
  );
};
