'use client';
import s from './Toggle.module.scss';
import { useCartActions } from '../../../lib/cartState/actions';
import { useCartSelectors } from 'lib/cartState/selectors';

const Toggle = () => {
  const { toggleProtectionAction, calculateTotalsAction } = useCartActions();
  const { withShippingProtection } = useCartSelectors();

  const handleChange = () => {
    toggleProtectionAction();
    calculateTotalsAction();
  };

  return (
    <label className={s.toggle}>
      <input type="checkbox" checked={withShippingProtection} className={s.toggle__input} onChange={handleChange} />
      <span className={s.toggle__slider}></span>
    </label>
  );
};

export default Toggle;
