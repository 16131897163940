import React from 'react';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import s from './slidingTextBar.module.scss';
import Marquee from 'react-fast-marquee';

export const SlidingTextBar = () => {
  const { slidingTextBar } = usewpdataSelectors();

  return (
    <div className={s.scrollingbar} style={{ background: slidingTextBar.styles.background }}>
      <div className={s.wrapper}>
        <Marquee>
          {slidingTextBar?.repeater &&
            slidingTextBar?.repeater.map((text: string, index: number) => (
              <div className={s.text} key={index} style={{ color: slidingTextBar.styles.textColor }}>
                {text.tr_benefit_text}
              </div>
            ))}
        </Marquee>
      </div>
    </div>
  );
};
