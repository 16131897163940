import React from 'react';
import './testimonials.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useMediaQuery } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import '@mantine/carousel/styles.css';
import classes from './carouselStyles.module.scss';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import TestimonialCard from './TestimonialCard';
import { decode } from 'html-entities';

const TestimonialSlides = () => {
  const { TestimonialsSwiper } = usewpdataSelectors();
  const { title, subtitle, list, ratedLabel } = TestimonialsSwiper;
  const theme = useMantineTheme();
  const mobile: boolean | undefined = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <section style={{ background: TestimonialsSwiper.styles.background }}>
      <div className="testimonials--container">
        <SectionTitle heading={title} subheading={subtitle} type="section" />
        {list && (
          <Carousel
            classNames={classes}
            withIndicators
            slideSize={mobile ? '72%' : '33.3333%'}
            slideGap="md"
            loop={true}
            initialSlide={mobile ? 2 : 0}
            align={mobile ? 'center' : 'start'}
          >
            {list.map((item, index) => {
              return (
                <Carousel.Slide key={index}>
                  <TestimonialCard
                    tr_heading={item.tr_heading}
                    score={item.score}
                    user_name={item.user_name}
                    tr_description={item.tr_description}
                  />
                </Carousel.Slide>
              );
            })}
          </Carousel>
        )}
        <div className="testimonial-text--rated">{decode(ratedLabel)}</div>
      </div>
    </section>
  );
};

export default TestimonialSlides;
