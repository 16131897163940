import { Field, FormikErrors } from 'formik';
import { ReviewModalFieldNames, ReviewModalFormFields } from './ReviewModal';
import s from './ReviewModalInput.module.scss';

type ReviewModalInputProps = {
  input: ReviewModalFieldNames;
  label: string;
  placeholder: string;
  values: ReviewModalFormFields;
  errors: FormikErrors<ReviewModalFormFields>;
  isTyped?: string | boolean;
  isTextArea?: boolean;
};

export const ReviewModalInput = ({
  input,
  label,
  placeholder,
  values,
  errors,
  isTyped,
  isTextArea = false,
}: ReviewModalInputProps) => (
  <div className={`${s.reviewModalInput__inputBox} ${s[`reviewModalInput__inputBox--${input}`]}`}>
    <label className={s.reviewModalInput__label} htmlFor={input}>
      {label}
    </label>
    <Field
      className={`${s.reviewModalInput__input} ${
        values[input]?.length !== 0 ? s['reviewModalInput__input--active'] : ''
      }`}
      style={{
        border: `1px solid ${isTyped ? '#E34400' : '#E5E5E5'}`,
      }}
      id={input}
      name={input}
      placeholder={placeholder}
      as={isTextArea && 'textarea'}
    />
    {isTyped && <div className={s.reviewModalInput__errorMessage}>{errors.name}</div>}
  </div>
);
