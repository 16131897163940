import s from './CartUpsellCard.module.scss';
import RemoveIcon from '../Icons/RemoveIcon';
import { formatPrice } from 'utils/helpers';
import { cartProductItem } from 'lib/cartState/types';
import { useCartActions } from 'lib/cartState/actions';
import { upsellVariantItem } from 'lib/productsState/productsTypes';

type props = {
  cartItem: cartProductItem['product'];
  variant: upsellVariantItem[];
  type: 'regular' | 'bundle';
};

const CartUpsellCard = ({ cartItem, variant, type = 'regular' }: props) => {
  const { removeUpsellFromCartAction, adjustUpsellQuantityAction } = useCartActions();
  console.log('cartItem:', cartItem, 'variant:', variant);

  const increaseQuantity = () => {
    adjustUpsellQuantityAction(cartItem.id, Number(cartItem.amount) + 1);
  };
  const decreaseQuantity = () => {
    if (cartItem.amount > 1) {
      adjustUpsellQuantityAction(cartItem.id, Number(cartItem.amount) - 1);
    }
  };

  let productImage = '';
  if (!cartItem.enable_variants && variant[0] && variant[0].color_image?.url) {
    productImage = variant[0].color_image?.url ? variant[0].color_image?.url : cartItem.product_image;
  } else if (type === 'bundle') {
    productImage = cartItem.image.url;
  } else {
    productImage = cartItem.product_image;
  }

  return (
    <>
      <div className={s.cartUpsellCard}>
        <div className={s.quantityWrapper}>
          <div className={s.imageWrapper}>
            <div className={s.sumOfItem}>{cartItem.amount}x</div>
            <div className={s.image}>
              <img className={s.productImage} src={productImage} alt={cartItem.tr_display_name} />
            </div>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.upper}>
            <div className={s.titles}>
              <div className={s.title}>{cartItem.tr_display_name ?? cartItem.display_name}</div>
              <div className={s.description}>
                {type != 'bundle'
                  ? variant
                      .filter(Boolean)
                      .map((v) => v.tr_label)
                      .join(' | ')
                  : variant.map((v) => {
                      return <p>{v.products_list[0].tr_title}</p>;
                    })}
              </div>
            </div>
            <div
              className={s.remove}
              onClick={() => {
                removeUpsellFromCartAction(cartItem.id);
              }}
            >
              <RemoveIcon />
            </div>
          </div>
          <div className={s.lower}>
            <div className={s.prices}>
              <div className={s.price}>{cartItem.price && formatPrice(cartItem.price)}</div>
              <div className={s.oldPrice}>{cartItem.old_price && formatPrice(cartItem.old_price)}</div>
            </div>

            <div className={s.quantity}>
              <button className={s.decrease} onClick={decreaseQuantity}>
                <span style={{ backgroundColor: cartItem.amount > 1 ? '#404040' : '#E5E5E5' }}></span>
              </button>
              <div className={s.number}>{cartItem.amount}</div>
              <button className={s.increase} onClick={increaseQuantity}>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartUpsellCard;
