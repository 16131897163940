'use client';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { CloseIcon } from '../SharedIcons/CloseIcon';
import s from './ModalWrapper.module.scss';

type ModalWrapperProps = {
  isOpen: boolean;
  onClose: () => void;
  showHeader: boolean;
  title?: string;
  children: React.ReactNode;
};

const customStyles = {
  content: {
    zIndex: 101,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 100,
  },
};

export const ModalWrapper = ({ isOpen, onClose, showHeader, title, children }: ModalWrapperProps) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return ReactDOM.createPortal(
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={s.modalWrapper}
      style={customStyles}
      contentLabel="Popup"
      ariaHideApp={false}
    >
      {showHeader && (
        <div className={s.modalWrapper__header}>
          <h2 className={s.modalWrapper__title}>{title}</h2>
          <a className={s.modalWrapper__closeButton} onClick={onClose}>
            <CloseIcon />
          </a>
        </div>
      )}
      <div className={s.modalWrapper__content}>{children}</div>
    </Modal>,
    document.body
  );
};
