import React from 'react';
import s from './DiscountNotice.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

const DiscountNotice = () => {
  const { Cart } = usewpdataSelectors();

  return (
    <div className={s.discountNotice} style={{ background: Cart.styles.colorPrimary }}>
      {Cart.dividerLabel}
    </div>
  );
};

export default DiscountNotice;
