import React from 'react';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import s from './logoSlider.module.scss';
import Marquee from 'react-fast-marquee';

const LogoSlider = () => {
  const { logoSlider } = usewpdataSelectors();

  return (
    <div className={s.logoSlider__wrapper} style={{ background: logoSlider.styles.background }}>
      <div className={s.logoSlider}>{logoSlider.staticImage && <img src={logoSlider.staticImage} alt="alt" />}</div>
      <div className={s.wrapper}>
        <Marquee style={{ height: '24px' }} className={s.logosMobile}>
          {logoSlider.repeater &&
            logoSlider.repeater.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item?.slides_repeater_single_slide}
                  alt="alt"
                  style={{
                    marginRight: '48px',
                    position: 'relative',
                    top: '4px',
                  }}
                />
              );
            })}
        </Marquee>
      </div>
    </div>
  );
};

export default LogoSlider;
