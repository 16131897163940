import { upsellItem } from 'lib/productsState/productsTypes';

type props = {
  onClick: () => {
    payload: upsellItem | undefined;
    type: 'cart/setUpsellModal';
  };
};

export const Close = ({ onClick }: props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <g id="pajamas:close">
      <rect width="24" height="24" rx="4" fill="#FAFAFA" />
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.55264 6.24155C7.38267 6.08257 7.15785 5.99603 6.92556 6.00014C6.69327 6.00425 6.47163 6.09871 6.30735 6.2636C6.14307 6.4285 6.04897 6.65096 6.04487 6.88412C6.04077 7.11728 6.127 7.34294 6.28538 7.51355L10.7327 11.9776L6.28538 16.4416C6.19729 16.5239 6.12663 16.6233 6.07762 16.7337C6.02862 16.8441 6.00226 16.9633 6.00014 17.0841C5.99802 17.205 6.02016 17.325 6.06526 17.4371C6.11036 17.5491 6.17748 17.6509 6.26262 17.7364C6.34777 17.8219 6.44919 17.8892 6.56083 17.9345C6.67248 17.9798 6.79207 18.002 6.91246 17.9999C7.03286 17.9977 7.15159 17.9713 7.26158 17.9221C7.37156 17.8729 7.47055 17.802 7.55264 17.7136L12 13.2496L16.4474 17.7136C16.5294 17.802 16.6284 17.8729 16.7384 17.9221C16.8484 17.9713 16.9671 17.9977 17.0875 17.9999C17.2079 18.002 17.3275 17.9798 17.4392 17.9345C17.5508 17.8892 17.6522 17.8219 17.7374 17.7364C17.8225 17.6509 17.8896 17.5491 17.9347 17.4371C17.9798 17.325 18.002 17.205 17.9999 17.0841C17.9977 16.9633 17.9714 16.8441 17.9224 16.7337C17.8734 16.6233 17.8027 16.5239 17.7146 16.4416L13.2673 11.9776L17.7146 7.51355C17.873 7.34294 17.9592 7.11728 17.9551 6.88412C17.951 6.65096 17.8569 6.4285 17.6926 6.2636C17.5284 6.09871 17.3067 6.00425 17.0744 6.00014C16.8421 5.99603 16.6173 6.08257 16.4474 6.24155L12 10.7056L7.55264 6.24155Z"
        fill="black"
      />
    </g>
  </svg>
);
