import s from './boastBlock.module.scss';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';

const ExpertBlock = () => {
  const { Expert } = usewpdataSelectors();
  return (
    <div className={s.expertBlock}>
      <div className={s.leftBlock}>
        <div className={s.title}>{Expert.title}</div>
        <div className={s.subtitle}>{Expert.subtitle}</div>
        <div className={s.divider}></div>
        <p className={s.text}>{Expert.text}</p>
        <div className={s.signature}>
          <p className={s.name}>{Expert.name}</p>
          {Expert.signatureImgUrl && <img src={Expert.signatureImgUrl} alt={Expert.name} />}
        </div>
      </div>
      {Expert.avatarUrl && <img className={s.avatar} src={Expert.avatarUrl} alt={Expert.name} />}
    </div>
  );
};

export default ExpertBlock;
