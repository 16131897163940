type CameraIconProps = {
  className?: string;
};

const CameraIcon = ({ className = '' }: CameraIconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_4124_19126)">
        <mask
          id="mask0_4124_19126"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="24"
        >
          <path d="M0.5 0H24.5V24H0.5V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_4124_19126)">
          <path
            d="M3.5 8C3.5 8.55 3.95 9 4.5 9C5.05 9 5.5 8.55 5.5 8V6H7.5C8.05 6 8.5 5.55 8.5 5C8.5 4.45 8.05 4 7.5 4H5.5V2C5.5 1.45 5.05 1 4.5 1C3.95 1 3.5 1.45 3.5 2V4H1.5C0.95 4 0.5 4.45 0.5 5C0.5 5.55 0.95 6 1.5 6H3.5V8Z"
            fill="#404040"
          />
          <path
            d="M13.5 17C15.1569 17 16.5 15.6569 16.5 14C16.5 12.3431 15.1569 11 13.5 11C11.8431 11 10.5 12.3431 10.5 14C10.5 15.6569 11.8431 17 13.5 17Z"
            fill="#404040"
          />
          <path
            d="M21.5 6H18.33L17.09 4.65C16.72 4.24 16.18 4 15.62 4H9.22C9.39 4.3 9.5 4.63 9.5 5C9.5 6.1 8.6 7 7.5 7H6.5V8C6.5 9.1 5.6 10 4.5 10C4.13 10 3.8 9.89 3.5 9.72V20C3.5 21.1 4.4 22 5.5 22H21.5C22.6 22 23.5 21.1 23.5 20V8C23.5 6.9 22.6 6 21.5 6ZM13.5 19C10.74 19 8.5 16.76 8.5 14C8.5 11.24 10.74 9 13.5 9C16.26 9 18.5 11.24 18.5 14C18.5 16.76 16.26 19 13.5 19Z"
            fill="#404040"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4124_19126">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CameraIcon;
