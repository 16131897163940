import { useState } from 'react';
import Slider from 'react-slick';
import s from './ReviewsSwiper.module.scss';
import { reviewSwiperSettings } from './utils/sliderSettings';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import ReviewCard from './ReviewsCard/ReviewCard';
export type Testimonial = {
  testimonialMedia: string;
  productImage: string;
  testimonialText: string;
  rating: number;
  author: string;
  videoId: string;
  id: string;
};
const ReviewsSwiper = () => {
  const { hero } = usewpdataSelectors();
  const [playingCardId, setPlayingCardId] = useState<string | null>(null);

  return (
    <div className={s.testimonialsContainer}>
      <div className={s.testimonialsContainer__wrapper}>
        <section className={`${s.testimonialsContainer__sliderWrapper}`}>
          <Slider
            {...reviewSwiperSettings}
            appendDots={(dots) => (
              <div>
                <ul className={s.dots}> {dots} </ul>
              </div>
            )}
            afterChange={() => setPlayingCardId(null)}
          >
            {hero.testimonials &&
              hero.testimonials.map((card, index) => (
                <ReviewCard
                  key={index}
                  tr_title={card.tr_title}
                  tr_description={card.tr_description}
                  user_name={card.user_name}
                />
              ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};
export default ReviewsSwiper;
