type ArrowDownIconProps = {
  className?: string;
  fill: string;
};

const Checkmark = ({ fill, className }: ArrowDownIconProps) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.98828 16.0459C10.0389 16.0459 11.0791 15.839 12.0497 15.4369C13.0204 15.0349 13.9023 14.4456 14.6451 13.7028C15.388 12.9599 15.9773 12.078 16.3793 11.1074C16.7814 10.1368 16.9883 9.09647 16.9883 8.0459C16.9883 6.99532 16.7814 5.95504 16.3793 4.98443C15.9773 4.01383 15.388 3.13191 14.6451 2.38904C13.9023 1.64618 13.0204 1.0569 12.0497 0.654862C11.0791 0.252824 10.0389 0.0458984 8.98828 0.0458984C6.86655 0.0458985 4.83172 0.888753 3.33143 2.38904C1.83114 3.88933 0.988281 5.92417 0.988281 8.0459C0.988281 10.1676 1.83114 12.2025 3.33143 13.7028C4.83172 15.203 6.86655 16.0459 8.98828 16.0459ZM8.78206 11.2815L13.2265 5.94812L11.8612 4.81034L8.03895 9.39612L6.06117 7.41745L4.80428 8.67434L7.47095 11.341L8.15895 12.029L8.78206 11.2815Z"
        fill={fill ? fill : '#4F4948'}
      />
    </svg>
  );
};

export default Checkmark;
