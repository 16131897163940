import * as React from 'react';
import { useState } from 'react';
import Accordion from '../Accordion/Accordion';
import s from './FAQ.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import SectionTitle from 'components/SectionTitle/SectionTitle';

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

export const FAQ = ({ children }: Props) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const { FAQ } = usewpdataSelectors();

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div id="faq" className={s.faq} style={{ background: FAQ.styles.background }}>
      <SectionTitle heading={FAQ.sectionTitle} type="section" />
      <div className={s.accordionContainer}>
        {FAQ.items &&
          FAQ.items?.map((faq, index) => (
            <Accordion
              key={index}
              index={index}
              title={faq?.tr_question}
              description={faq?.tr_answer}
              isOpen={index === openIndex}
              toggle={toggleAnswer}
              size="large"
            />
          ))}
      </div>
      {children}
    </div>
  );
};
