import { v4 as uuidv4 } from 'uuid';
import { VariantType, variantObjectType } from '../lib/productsState/productsTypes';

function initializeVariantPickerState(
  colorVariants: VariantType[],
  sizeVariants: VariantType[],
  maxAmount: number
): variantObjectType[] | void {
  const [defaultColorVariant] =
    (colorVariants && colorVariants?.filter((variant: VariantType) => variant.default_variant === true)) || [];
  const [defaultSizeVariant] =
    (sizeVariants && sizeVariants?.filter((variant: VariantType) => variant.default_variant === true)) || [];

  const defaultVariants = {
    colorValue: defaultColorVariant?.value,
    colorLabel: defaultColorVariant?.tr_label,
    sizeValue: defaultSizeVariant?.value,
    sizeLabel: defaultSizeVariant?.tr_label,
  };

  if (defaultColorVariant || defaultSizeVariant) {
    return Array(maxAmount)
      .fill(null)
      .map((_) => {
        return {
          id: uuidv4(),
          colorValue: defaultVariants?.colorValue,
          colorLabel: defaultVariants?.colorLabel,
          sizeValue: defaultVariants?.sizeValue,
          sizeLabel: defaultVariants?.sizeLabel,
        };
      });
  }
  return;
}

export default initializeVariantPickerState;
