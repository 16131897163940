import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import s from './styles/heroTitles.module.scss';
import RatingRow from './RatingRow';

const HeroTitles = () => {
  const { hero, Theme } = usewpdataSelectors();

  return (
    <div className={s.heroTitles}>
      <RatingRow />
      <h1
        style={{ color: hero.styles.textColorPrimary }}
        data-theme={Theme.heading}
        style={{ color: Theme.headingColor }}
      >
        {hero.title}
      </h1>
      <h4 style={{ color: hero.styles.textColorPrimary }}>{hero.subtitle}</h4>
    </div>
  );
};

export default HeroTitles;
