import { useRef } from 'react';
import c from './Accordion.module.scss';
import { ChevronDown } from '../SharedIcons/ChevronDown';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

type AccordionProps = {
  index?: number;
  icon?: string;
  title: string;
  description: string;
  isOpen: boolean;
  toggle: (index: number) => void;
  size: 'small' | 'large';
  customStyles?: {
    [key: string]: string;
  };
};

const Accordion = ({ index, icon, title, description, isOpen, toggle, size, customStyles }: AccordionProps) => {
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const s = customStyles ?? c;
  const getHeight = () => (isOpen && descriptionRef.current ? `${descriptionRef.current.scrollHeight + 16}px` : '0');
  const { hero } = usewpdataSelectors();
  const getPadding = () => (isOpen ? `16px` : '0');

  return (
    <div
      className={`${s.accordion} ${size === 'small' ? s['accordion--small'] : s['accordion--large']}`}
      onClick={() => toggle(index || 0)}
    >
      <div className={s.accordion__titleBox}>
        <div className={s.accordion__border}>
          <h3
            style={{ color: hero.styles.textColorPrimary }}
            className={`${s.accordion__title} ${
              size === 'small' ? s['accordion__title--small'] : s['accordion__title--large']
            }`}
          >
            {icon && <img src={icon} className={s.accordion__icon} alt="alt" />}
            {title}
          </h3>
          <ChevronDown className={`${s.accordion__openIcon} ${isOpen ? `${s['accordion__openIcon--active']}` : ''}`} />
        </div>
        <p
          className={s.accordion__descriptionWrapper}
          style={{ paddingTop: getPadding(), maxHeight: getHeight(), color: hero.styles.textColorSecondary }}
          ref={descriptionRef}
        >
          <span
            style={{ color: hero.styles.textColorSecondary }}
            className={`${s.accordion__description} ${
              size === 'small' ? s['accordion__description--small'] : s['accordion__description--large']
            }`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </p>
      </div>
    </div>
  );
};

export default Accordion;
