import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './ContentBlock.module.scss';

const RatingRow = () => {
  const { hero, Settings } = usewpdataSelectors();
  return (
    <div className={s.ratingRow}>
      <div className={s.avatars}>
        <div className={s.avatars_container}>
          <img src={`${Settings.root}/temp_images/avatars.png`} className={s.avatars__img} alt="alt" />
        </div>
      </div>
      <div className={s.review} style={{ color: hero.styles.textColorSecondary }}>
        {hero.bought_this}
      </div>
    </div>
  );
};

export default RatingRow;
