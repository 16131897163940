import React from 'react';
import Button from '../Button/CTA/Button';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

type FeaturesCtaProps = {
  label: string;
  stars: number;
  score: number;
};

const FeaturesCta: React.FC<FeaturesCtaProps> = ({ label, stars, score }) => {
  const { FeaturesList } = usewpdataSelectors();

  return (
    <div className="featuresList-ctaBlock">
      <Button text={label} link="blank" attribute="cta-features" className="featuresList-ctaBlock__cta" />
      <div className="featuresList-ctaBlock__score">
        <img className="featuresList-ctaBlock__image" src={`${window.template_root}/stars/${stars}.svg`} alt="score" />
        <span className="featuresList-ctaBlock__reviews" style={{ color: FeaturesList.styles.textColorSecondary }}>
          <strong>{score}</strong>
          <div className="featuresList-ctaBlock__divider"></div>
          <span>Verified Reviews</span>
        </span>
      </div>
    </div>
  );
};

export default FeaturesCta;
