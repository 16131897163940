import SectionTitle from 'components/SectionTitle/SectionTitle';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import SetupCard from './SetupCard';
import './setup.scss';

const Setup = () => {
  const { Setup } = usewpdataSelectors();

  return (
    <section style={{ backgroundColor: Setup.styles.background }}>
      <div className="featuresList--container">
        <SectionTitle heading={Setup.title} subheading={Setup.subtitle} type="section" />
        <div className="easySteps__stepsContainer">
          {Setup.list &&
            Setup.list.map((item, index) => {
              return <SetupCard key={index} {...item} />;
            })}
        </div>
      </div>
    </section>
  );
};

export default Setup;
