import s from './LinkList.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

export const LinkList = () => {
  const { header } = usewpdataSelectors();

  return (
    <>
      {header.headerNavigationLinks &&
        header.headerNavigationLinks.map((item, index) => (
          <li className={`${s.linkList__listItem} ${index === 0 && 'linkList__firstChild'}`} key={index}>
            {item.link_type === 'custom' ? (
              <a href={item.custom_link.custom_link} className={`${s.linkList__listLink} ${s.linkList__listItemText}`}>
                {item.custom_link.tr_custom_link_label}
              </a>
            ) : (
              <a href={item.page_link.page_link_uri} className={`${s.linkList__listLink} ${s.linkList__listItemText}`}>
                {item.page_link.tr_page_link_label}
              </a>
            )}
          </li>
        ))}
    </>
  );
};
