import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import VideoPlayer from './VideoPlayer';
import FeaturesListInner from './InnerList';
import FeaturesCta from './FeaturesCta';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import './featuresList.scss';

const FeaturesList = () => {
  const { FeaturesList } = usewpdataSelectors();
  return (
    <div style={{ background: FeaturesList.styles.background }}>
      <div className="featuresList--container">
        <div className="featuresList__list">
          {FeaturesList.list &&
            FeaturesList.list.map((item, index) => {
              const {
                listItem_ctaBtn,
                listItem_innerList,
                listItem_innerListRepeater,
                listItem_productIamge,
                listItem_useVideo,
                listItem_videoId,
                tr_listItem_ctaLabel,
                tr_listItem_description,
                tr_listItem_heading,
              } = item;

              return (
                <div className="featuresList-card" key={index}>
                  {listItem_useVideo ? (
                    <VideoPlayer video_id={listItem_videoId} />
                  ) : (
                    <div className="featuresList-card__image">
                      <img
                        src={listItem_productIamge}
                        alt="alt text"
                        className="mon-img-960-w"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                  )}
                  <div className="featuresList-card__content">
                    <SectionTitle type="fragment" heading={tr_listItem_heading} />
                    <div
                      className="featuresList-card__description"
                      style={{ color: FeaturesList.styles.textColorSecondary }}
                      dangerouslySetInnerHTML={{
                        __html: tr_listItem_description,
                      }}
                    ></div>
                    {listItem_innerList && <FeaturesListInner innerList={listItem_innerListRepeater} />}
                    {listItem_ctaBtn && (
                      <FeaturesCta
                        label={tr_listItem_ctaLabel}
                        score={FeaturesList.score}
                        stars={FeaturesList.starsValue}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FeaturesList;
