import { Image } from '@mantine/core';
import s from './productCard.module.scss';
import { ProductSectionItem } from '../../lib/wpdataState/wpDataType';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';

type ProductCardProps = {
  productsData: ProductSectionItem[];
};

const ProductCard = ({ productsData }: ProductCardProps) => {
  const {
    product_image,
    product_rating,
    tr_product_heading,
    product_oldPrice,
    product_price,
    product_link,
    tr_product_discount,
    tr_reviews_label,
    tr_product_bestseller,
  } = productsData[0];

  const { productsSection, Settings } = usewpdataSelectors();

  const price = Settings.formattedString.replace(/(0\.00|0,00|0)/, product_price.toString());
  const oldPrice = Settings.formattedString.replace(/(0\.00|0,00|0)/, product_oldPrice.toString());

  return (
    <a data-cy="cta-products" href={product_link} className={s.productCardContainer}>
      <div className={s.productCardContainer__imageWrapper}>
        {tr_product_bestseller && (
          <div
            style={{
              background: productsSection.styles.backgroundAccent,
              color: productsSection.styles.accentColor,
            }}
          >
            {tr_product_bestseller}
          </div>
        )}
        <Image src={product_image} alt="alt" className={s.productCardContainer__productImage} />
      </div>
      <div className={s.productCardContainer__content}>
        <h3 className={s.productCardContainer__title} style={{ color: productsSection.styles.textColorPrimary }}>
          {tr_product_heading}
        </h3>
        <div className={s.productCardContainer__reviewsWrapper}>
          <img src={`${window.template_root}/stars/${product_rating}.svg`} alt="text" />
          <span
            className={s.reviewsNumber}
            style={{
              color: productsSection.styles.textColorSecondary,
              position: 'relative',
              top: '1px',
            }}
          >
            {tr_reviews_label}
          </span>
        </div>
        <div className={s.productCardContainer__productPriceWrapper}>
          <p className={s.discountPrice} style={{ color: productsSection.styles.textColorPrimary }}>
            {price}
          </p>
          <p className={s.price} style={{ color: productsSection.styles.textColorSecondary }}>
            {oldPrice}
          </p>
          <span className={s.discountText} style={{ background: productsSection.styles.backgroundBadge }}>
            {tr_product_discount}
          </span>
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
