import { useEffect, useState } from 'react';
import s from './Cart.module.scss';
import Bag from './IconComponents/Bag';
import Cross from './IconComponents/Cross';
import { ShippingGift } from './ShippingGift/ShippingGift';
import { useCartActions } from '../../lib/cartState/actions';
import { useCartSelectors } from '../../lib/cartState/selectors';
import ProductCard from './ProductCard/ProductCard';
import DiscountNotice from './DiscountNotice/DiscountNotice';
import Upsells from './Upsells/Upsells';
import Summary from './Summary/Summary';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { consolidateVariants } from 'utils/cartHelpers';
import CartUpsellCard from './CartUpsellCard/CartUpsellCard';
import BundleUpgrade from './Upsells/bundleUpgrade';
import BundleUpsell from './Upsells/BundleUpsell';

export const Cart = () => {
  const [variantsConsolidated, setVariantsConsolidated] = useState<{ colorLabel: string; quantity: number }[]>();
  const { toggleCartAction, calculateTotalsAction, setProductsQuantityAction } = useCartActions();
  const { isCartOpen, productsQuantity, cartProducts } = useCartSelectors();
  const { ProductColorVariants, ProductSizeVariants, Products } = useproductsdataSelectors();
  const { Settings, Cart } = usewpdataSelectors();
  useEffect(() => {
    calculateTotalsAction();
    setProductsQuantityAction();
  }, [cartProducts]);

  useEffect(() => {
    const variantsConsolidated = consolidateVariants(
      cartProducts.mainProduct.variants.slice(0, Number(cartProducts.mainProduct.product.amount)),
      ProductColorVariants,
      ProductSizeVariants
    );
    setVariantsConsolidated(variantsConsolidated);
  }, [cartProducts]);

  return (
    <section
      className={`${s.cart} ${isCartOpen ? s['car t--open'] : ''}`}
      style={{ pointerEvents: isCartOpen === false ? 'none' : undefined }}
    >
      <div
        className={`${s.cart__overlay} ${isCartOpen ? s['cart__overlay--open'] : ''}`}
        onClick={toggleCartAction}
      ></div>
      <section className={`${s.cart__content} ${isCartOpen ? s['cart__content--open'] : ''}`}>
        <div className={s.cart__header}>
          <div className={s.cart__bagBox}>
            <Bag className={s.cart__bag} />
            <div className={s.cart__itemNumber} style={{ background: Cart.styles.accentColor }}>
              {productsQuantity}
            </div>
          </div>
          <h3 className={s.cart__text}>{Cart.cartLabel}</h3>
          <button className={s.cart__cross} onClick={toggleCartAction}>
            <div className={s.cart__crossBg}></div>
            <Cross />
          </button>
        </div>
        <ShippingGift />
        <div className={s.productList}>
          {variantsConsolidated && variantsConsolidated.length > 0 ? (
            variantsConsolidated.map((variant: any) => (
              <ProductCard
                cartItem={cartProducts.mainProduct.product}
                type="main-variants"
                variant={variant.colorVariant}
                quantity={variant.quantity}
                sizeLabel={variant.sizeLabel}
                colorLabel={variant.colorLabel}
              />
            ))
          ) : (
            <ProductCard cartItem={cartProducts.mainProduct.product} type="main" />
          )}

          {cartProducts?.upsells?.map((upsellProduct, index) => {
            console.log(cartProducts?.upsells);
            return (
              upsellProduct && (
                <CartUpsellCard
                  key={index}
                  cartItem={upsellProduct.product}
                  variant={upsellProduct.variant}
                  type={upsellProduct.product.type ?? 'regular'}
                />
              )
            );
          })}
        </div>

        <DiscountNotice />
        <div className={s.lower}>
          {Settings.bundleMode && <BundleUpgrade />}

          <Upsells />
          {cartProducts.mainProduct.bundle.length > 0 && (
            <div className={s.bundleProducts}>
              {Products.map((product, key) => {
                const existingProductInCart = cartProducts.upsells.find(
                  (cartProduct) => cartProduct.product.id === product.id
                );
                if (!existingProductInCart) {
                  return <BundleUpsell product={product} key={key} />;
                }
              })}
            </div>
          )}
        </div>
        <Summary />
      </section>
    </section>
  );
};
