import s from '../ImageSlider.module.scss';
type Props = {
  onClick?: () => void;
  arrowsInMobile?: boolean;
};

const SliderPrevIcon = ({ onClick, arrowsInMobile = false }: Props) => (
  <svg
    className={arrowsInMobile ? s.imageSlider__prevSlide : `${s.imageSlider__displayNone} ${s.imageSlider__prevSlide}`}
    onClick={onClick}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="32" width="32" height="32" rx="6.4" transform="rotate(-90 0 32)" fill="#F6F6F9" />
    <path
      d="M18.6667 9.33342L12 16.0001L18.6667 22.6667"
      stroke="#B3C1E5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SliderPrevIcon;
