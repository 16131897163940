import React from 'react';
import ProductCard from './ProductCard';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';
import classes from './CarouseStyles.module.scss';
import '@mantine/carousel/styles.css';
import s from './styles.module.scss';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';

const ProductSection = () => {
  const { productsSection } = usewpdataSelectors();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const displayOnlyMobile = productsSection.products.length <= 4;

  return (
    <div className={s.productSections} id="products">
      {productsSection?.products &&
        productsSection.products.map((section, index) => (
          <section
            key={index}
            className={`${s.productSection}`}
            style={{ background: productsSection.styles.background }}
          >
            <div className={s.productSection__wrapper}>
              <SectionTitle heading={section.tr_product_page_v6_products_heading} type="section" />
              <Carousel
                classNames={classes}
                withIndicators={false}
                loop={true}
                initialSlide={mobile ? 2 : 0}
                style={
                  (!displayOnlyMobile && !mobile) || (displayOnlyMobile && mobile)
                    ? { display: 'block' }
                    : { display: 'none' }
                }
                slideGap="md"
                slideSize={mobile ? '72%' : '25%'}
                align={mobile ? 'center' : 'start'}
              >
                {section.product_page_v6_products_repeater.map((product, index) => (
                  <Carousel.Slide key={index}>
                    <ProductCard productsData={[product]} />
                  </Carousel.Slide>
                ))}
              </Carousel>
              <div
                className={s.productsList__static}
                style={
                  displayOnlyMobile && !mobile ? { display: 'flex', justifyContent: 'center' } : { display: 'none' }
                }
              >
                {section.product_page_v6_products_repeater.map((product, index) => (
                  <ProductCard productsData={[product]} key={index} />
                ))}
              </div>
            </div>
          </section>
        ))}
    </div>
  );
};

export default ProductSection;
