import VariantOption from 'components/ProductHero/QuantityCards/VariantPickers/VariantOption';
import React, { useEffect, useRef, useState } from 'react';
import UpsellVariantPickerOption from './UpsellVariantPickerOption';
import '../../ProductHero/QuantityCards/VariantPickers/variantsStyles.scss';
import { upsellVariantItem } from 'lib/productsState/productsTypes';
import s from './Upsells.module.scss';

type props = {
  variants: upsellVariantItem[];
  type: 'size' | 'color';
  selectedVariant: upsellVariantItem;
  setVariant: React.Dispatch<React.SetStateAction<upsellVariantItem | undefined>>;
};

const UpsellVariantPicker = ({ variants, type, selectedVariant, setVariant }: props) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current) {
        const target = event.target as Node;
        if (!selectRef.current.contains(target)) {
          setOpen(false);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={selectRef}
      className={`${open ? 'variant-select--active variant-select' : 'variant-select--passive variant-select'} ${
        s.variantSelect
      }`}
      onClick={() => {
        setOpen(!open);
      }}
      aria-expanded={open}
      aria-haspopup="true"
    >
      <div className="variant-select--label">
        <span>{selectedVariant.tr_label}</span>
        <div className="variant-select--chevron" data-expanded={open}>
          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.99999 5.5C4.84317 5.5 4.68637 5.43998 4.56681 5.32018L0.804499 1.5486C0.565167 1.30868 0.565167 0.919689 0.804499 0.679867C1.04373 0.440044 1.43169 0.440044 1.67104 0.679867L4.99999 4.01717L8.32895 0.679983C8.56828 0.440161 8.9562 0.440161 9.19541 0.679983C9.43486 0.919806 9.43486 1.30879 9.19541 1.54871L5.43316 5.3203C5.31354 5.44011 5.15675 5.5 4.99999 5.5Z"
              fill="#828282"
            />
          </svg>
        </div>
      </div>
      <div className="custom-select"></div>
      {open && (
        <div className="custom-select--hidden">
          <div className="custom-select__wrapper">
            <ul className="custom-select__inner">
              {variants &&
                variants.map((variant, index: number) => {
                  return (
                    <UpsellVariantPickerOption
                      key={index}
                      variant={variant}
                      type={type}
                      currentSelect={selectedVariant}
                      handleClick={() => {
                        setVariant(variant);
                      }}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpsellVariantPicker;
