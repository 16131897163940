type Props = {
  className?: string;
};

export const Discover = ({ className = '' }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="31" height="6" viewBox="0 0 31 6" fill="none">
    <path
      d="M18.8778 3.03193C18.8805 2.38462 18.6378 1.73637 18.1494 1.24786C17.6599 0.758343 17.01 0.515837 16.3613 0.519733C16.3583 0.519733 16.3552 0.519531 16.352 0.519531C14.9435 0.519531 13.8262 1.63654 13.8262 3.0455C13.8262 4.44055 14.9572 5.57127 16.352 5.57127C17.761 5.57127 18.8781 4.45406 18.8781 3.0455C18.8781 3.04086 18.8778 3.0365 18.8778 3.03193Z"
      fill="#F26E21"
    />
    <path
      d="M13.3567 4.11382C12.9792 4.49175 12.6307 4.6394 12.2554 4.6474C11.3411 4.6392 10.6688 3.96207 10.6688 2.99667C10.6688 2.51072 10.8528 2.11243 11.1443 1.77218C11.4177 1.51268 11.7698 1.345 12.1747 1.345C12.6119 1.345 12.952 1.49071 13.3404 1.87933V0.762114C12.9521 0.567907 12.6118 0.470703 12.1747 0.470703C11.5573 0.514838 10.975 0.764332 10.5284 1.1561C10.3997 1.27192 10.2764 1.39975 10.1718 1.54606C9.8456 1.96887 9.64844 2.4866 9.64844 3.04544C9.64844 4.45393 10.7661 5.52257 12.1747 5.52257C12.1815 5.52257 12.1883 5.52197 12.195 5.5219C12.2044 5.52204 12.2138 5.52257 12.2232 5.52257C12.6117 5.52257 12.952 5.42564 13.3892 5.2313L13.3567 4.11382C13.3403 4.13021 13.373 4.14546 13.3567 4.16097V4.11382Z"
      fill="#2B2B2B"
    />
    <path
      d="M22.0667 1.16665L20.9666 3.87131L19.7035 0.665039H18.6836L20.6751 5.57127H21.2094L23.2982 0.665039H22.2783L22.0667 1.16665Z"
      fill="#2B2B2B"
    />
    <path
      d="M23.6865 2.80246V5.42577H26.3098H26.3581V4.64827H25.5324H24.658V3.7741V3.33705H26.3098V2.51098H24.658V1.49097H26.3581V0.665039H23.6865V2.80246Z"
      fill="#2B2B2B"
    />
    <path
      d="M1.73051 0.665039H0.370117V5.42577H1.73044C2.45897 5.42577 2.99322 5.23129 3.43041 4.89124C3.96466 4.45406 4.30484 3.77417 4.30484 3.04564C4.30491 1.63655 3.28464 0.665039 1.73051 0.665039ZM2.84765 4.25992C2.55617 4.5027 2.16756 4.64827 1.5846 4.64827H1.29312V1.49091H1.53596C2.11892 1.49091 2.50754 1.58804 2.79908 1.87939C3.13906 2.17067 3.33347 2.60812 3.33347 3.04544C3.38204 3.48262 3.18777 3.96817 2.84765 4.25992Z"
      fill="#2B2B2B"
    />
    <path d="M5.66512 0.665039H4.74219V5.42563H5.66512V0.665039Z" fill="#2B2B2B" />
    <path
      d="M7.99595 2.50948C7.41312 2.3152 7.26722 2.16936 7.26722 1.92659C7.26722 1.63511 7.5587 1.39226 7.94731 1.39226C8.23879 1.39226 8.48163 1.48947 8.72454 1.78095L9.21043 1.14949C8.82168 0.809307 8.33613 0.615234 7.80174 0.615234C6.97574 0.615234 6.29564 1.19819 6.29564 1.97515C6.29564 2.65538 6.58739 2.9468 7.46163 3.28684C7.85024 3.43235 7.99602 3.48112 8.09335 3.57825C8.28729 3.67526 8.38463 3.86973 8.38463 4.06381C8.38463 4.45249 8.09335 4.74403 7.6559 4.74403C7.21831 4.74403 6.83003 4.50119 6.63569 4.11251L6.05273 4.69546C6.49012 5.32692 7.02444 5.61834 7.70454 5.61834C8.67598 5.61834 9.35573 4.98688 9.35573 4.06381C9.35587 3.23821 9.06466 2.89816 7.99595 2.50948Z"
      fill="#2B2B2B"
    />
    <path
      d="M28.982 3.43385C29.7104 3.28835 30.0992 2.80239 30.0992 2.07393C30.0992 1.1997 29.4676 0.665039 28.3991 0.665039H26.9902V5.42577H27.9132V3.53106H28.0591L29.3703 5.42577H30.4877L28.982 3.43385ZM28.2045 2.85089H27.9132V1.39377H28.2045C28.7875 1.39377 29.1277 1.63655 29.1277 2.12263C29.1277 2.60819 28.7875 2.85089 28.2045 2.85089Z"
      fill="#2B2B2B"
    />
  </svg>
);
