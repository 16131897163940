import { usewpdataSelectors } from 'lib/wpdataState/selectors';

export const formatTime = (time: number) => {
  return time < 10 ? `0${time}` : time;
};

export const formatPrice = (price: string | number) => {
  const priceFormatString = window?.formattedString;
  const priceNumber = typeof price === 'number' ? price : parseFloat(price);
  const formattedPrice = priceNumber.toFixed(2);

  if (priceFormatString) {
    const priceString = priceFormatString.replace(/(0\.00|0,00|0)/, formattedPrice);
    return priceString;
  } else {
    if (price) {
      return `$${formattedPrice}`;
    } else return;
  }
};
