import React from 'react';
import s from './desktopNav.module.scss';

const DesktopNav = ({ mainSlider }: any) => {
  const gotoNext = () => {
    mainSlider.slickNext();
  };

  const gotoPrev = () => {
    mainSlider.slickPrev();
  };

  return (
    <>
      <div className={s.arrowLeft} onClick={() => gotoPrev()}></div>
      <div
        className={s.arrowRight}
        onClick={() => {
          gotoNext();
        }}
      ></div>
    </>
  );
};

export default DesktopNav;
