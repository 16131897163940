import { combineReducers, configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartState/slice';
import checkoutReducer from './checkoutState/slice';
import wpdataReducer from './wpdataState/slice';
import productsdataReducer from './productsState/slice';
import thunk from 'redux-thunk'; // Ensure redux-thunk is imported

const rootReducer = combineReducers({
  cart: cartReducer,
  checkout: checkoutReducer,
  wpdata: wpdataReducer,
  productsdata: productsdataReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
