import { useState } from 'react';
import s from './ProductFAQ.module.scss';
import Accordion from 'components/Accordion/Accordion';
import { productFAQItem } from 'lib/wpdataState/wpDataType';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

export const ProductFAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : Number(index));
  };
  const { hero } = usewpdataSelectors();

  return (
    <div className={s.faq}>
      <div className={s.accordionContainer}>
        {hero.faqList &&
          hero.faqList?.map((faq: productFAQItem, index: number) => (
            <Accordion
              key={index}
              icon={faq.faq_icon}
              title={faq?.tr_faq_heading}
              description={faq?.tr_faq_answer}
              isOpen={index === openIndex}
              toggle={() => toggleAnswer(index)}
              size="large"
              customStyles={s}
            />
          ))}
      </div>
    </div>
  );
};
