import s from './CtaButton.module.scss';
import { ChevronRight } from '../../SharedIcons/ChevronRight';
import { useCartActions } from 'lib/cartState/actions';

type CtaButtonProps = {
  text: string;
  type?: 'button' | 'submit' | 'reset';
  isLink?: boolean;
  link?: string;
  style?: React.CSSProperties;
  hasArrowRight?: boolean;
  onClick?: () => void;
  className?: string;
};

export const CtaButton = ({
  text,
  type,
  isLink = false,
  link,
  style,
  hasArrowRight = false,
  onClick,
  className,
}: CtaButtonProps) => {
  const { toggleCartAction } = useCartActions();
  if (isLink && link) {
    return (
      <a href={link} className={`${s.ctaButton} ${className}`} style={style}>
        {text}
        {hasArrowRight && <ChevronRight />}
      </a>
    );
  }

  if (type === 'submit') {
    return (
      <button className={`${s.ctaButton} ${className}`} type={type} style={style}>
        {text}
        {hasArrowRight && <ChevronRight />}
      </button>
    );
  }

  return (
    <button className={`${s.ctaButton} ${className}`} type={type} style={style} onClick={toggleCartAction}>
      {text}
      {hasArrowRight && <ChevronRight />}
    </button>
  );
};
