type Props = {
  className?: string;
  color?: string;
};

export const ArrowDown = ({ className = '', color = '3A1F15' }: Props) => (
  <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.96615 6.66667L8.29948 10L11.6328 6.66667"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
