import { Modal, ScrollArea, UnstyledButton } from '@mantine/core';
import { useCartActions } from 'lib/cartState/actions';
import { useCartSelectors } from 'lib/cartState/selectors';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import s from './UpsellModal.module.scss';
import TitleBlock from './TitleBlock';
import ReviewsSwiper from './ReviewsSwiper';
import SwiperGallery from './SwiperGallery';
import Benefits from './Benefits';
import FAQ from './FAQ';
import UserGallery from './UsersGallery';
import { Close } from './icons/Close';
import Timer from 'components/Cart/Summary/Timer';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { formatPrice } from 'utils/helpers';
import { useMediaQuery } from '@mantine/hooks';

const UpsellModal = () => {
  const { UpsellModal: UpsellModalData } = useCartSelectors();
  const { setUpsellModalAction } = useCartActions();
  const { Settings } = usewpdataSelectors();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const isUpsellModalOpen = UpsellModalData !== undefined;
  return (
    <Modal
      opened={isUpsellModalOpen}
      size={'700px'}
      onClose={() => setUpsellModalAction(undefined)}
      styles={{ body: { padding: '0px' }, content: { borderRadius: isMobile ? '0px' : '16px' } }}
      classNames={{ header: s.UpsellModal__header, close: s.UpsellModal__closeBtn }}
      closeButtonProps={{ size: '24px' }}
      withCloseButton={false}
      fullScreen={isMobile}
    >
      {UpsellModalData && (
        <>
          <div className={s.UpsellModal__header}>
            <Close onClick={() => setUpsellModalAction(undefined)} />
          </div>
          <ScrollArea h={isMobile ? 'calc(100vh - 166px)' : 600} type="never" scrollbars="y">
            <div className={s.UpsellModal__content}>
              <TitleBlock />
              <SwiperGallery />
              <Benefits />
              <ReviewsSwiper />
              <FAQ />
            </div>
            <UserGallery />
          </ScrollArea>
          <div className={s.footer}>
            <button className={s.addToCartBtn}>{`Add to Cart | ${formatPrice(UpsellModalData.price)}`}</button>
            <Timer />
          </div>
        </>
      )}
    </Modal>
  );
};

export default UpsellModal;
