import React from 'react';
import classes from './imageCarousel.module.scss';
import s from './imageSlider.module.scss';
import { Carousel } from '@mantine/carousel';
import { usewpdataSelectors } from '../../../lib/wpdataState/selectors';
import { useMediaQuery } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';
import Marquee from 'react-fast-marquee';

const ImageSlider = () => {
  const { videoTestimonialsImages } = usewpdataSelectors();
  const theme = useMantineTheme();
  const mobile: boolean | undefined = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <div className={s.imageCarousel}>
      <Marquee>
        {videoTestimonialsImages.repeater &&
          videoTestimonialsImages.repeater.map((item, index) => {
            return <img src={item.user_image} alt="alt" className={s.imageCarousel__img} />;
          })}
      </Marquee>
    </div>
  );
};

export default ImageSlider;
