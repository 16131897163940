import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import { Stream, StreamPlayerApi } from '@cloudflare/stream-react';
import s from './VideoModal.module.scss';
import CloseIcon from './Icons/CameraIcon';
import AudioOffIcon from './Icons/AudioOffIcon';
import AudioOnIcon from './Icons/AudioOnIcon';
import ArrowUpIcon from './Icons/ArrowUpIcon';
import ArrowDownIcon from './Icons/ArrowDownIcon';
import PlayIcon from './Icons/PlayIcon';
import { useSwipe } from 'utils/useSwipe';

type VideoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  videoIds: { video_id: string }[];
  videoIndex: number;
  onPrevious: () => void;
  onNext: () => void;
};

const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, videoIds, videoIndex, onPrevious, onNext }) => {
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef<StreamPlayerApi>();
  useSwipe(onNext, onPrevious);

  const toggleAudio = () => {
    setIsMuted(!isMuted);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={s.modalContent}
      overlayClassName={s.modalOverlay}
      ariaHideApp={false}
    >
      <button className={`${s.modalOverlay__modalButton} ${s.modalOverlay__close}`} onClick={onClose}>
        <CloseIcon className={s.modalOverlay__buttonSvg} />
      </button>
      <div className={s.modalContent__videoContainer} onClick={togglePlayPause}>
        <Stream
          src={videoIds[videoIndex].cloudflare_video_id}
          controls={false}
          autoplay
          loop
          muted={isMuted}
          onPlay={handlePlay}
          onPause={handlePause}
          streamRef={videoRef}
        />
      </div>
      {!isPlaying && (
        <div className={`${s.modalOverlay__modalButton} ${s.modalOverlay__play}`}>
          <PlayIcon />
        </div>
      )}
      <button className={`${s.modalOverlay__modalButton} ${s.modalOverlay__up}`} onClick={onPrevious}>
        <ArrowUpIcon className={s.modalOverlay__buttonSvg} />
      </button>
      <button className={`${s.modalOverlay__modalButton} ${s.modalOverlay__down}`} onClick={onNext}>
        <ArrowDownIcon className={s.modalOverlay__buttonSvg} />
      </button>
      <button className={`${s.modalOverlay__modalButton} ${s.modalOverlay__mute}`} onClick={toggleAudio}>
        {isMuted ? (
          <AudioOffIcon className={s.modalOverlay__buttonSvg} />
        ) : (
          <AudioOnIcon className={s.modalOverlay__buttonSvg} />
        )}
      </button>
    </Modal>
  );
};

export default VideoModal;
