import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import s from './styles/ctaButton.module.scss';
import Button from '../Button/CTA/Button';

const CtaButton = () => {
  const { hero } = usewpdataSelectors();
  return <Button link="href" className={s.button} attribute="cta-hero" text={hero.ctaButtonText}></Button>;
};

export default CtaButton;
