import { useRef } from 'react';
import s from './PageRouteMobile.module.scss';
import { ArrowDown } from '../../SharedIcons/CreditCards/ArrowDown';

type PageRouteMobileProps = {
  index: number;
  header: string;
  links: {
    footer_link_type: boolean;
    footer_custom_link?: {
      tr_footer_custom_link_label: string;
      footer_custom_link_href: string;
    };
    footer_post_link?: {
      tr_footer_post_link_label: string;
      footer_post_link_href: string;
    };
  };
  isOpen: boolean;
  toggleAnswer: (index: number) => void;
};

export const PageRouteMobile = ({ index, header, links, isOpen, toggleAnswer }: PageRouteMobileProps) => {
  const answerRef = useRef<HTMLDivElement>(null);

  const getHeight = () => (isOpen ? `${answerRef?.current?.scrollHeight}px` : '0');

  return (
    <li className={s.mobileRoute}>
      <div className={s.mobileRoute__linkHeader} onClick={() => toggleAnswer(index)}>
        <h3 className={s.mobileRoute__heading}>{header}</h3>
        <ArrowDown
          className={`${s.mobileRoute__arrow} ${isOpen ? `${s['mobileRoute__arrow--open']}` : ''}`}
          color="#fff"
        />
      </div>
      <div className={s.mobileRoute__links} style={{ maxHeight: getHeight() }} ref={answerRef}>
        <div className={s.mobileRoute__linksWrapper}>
          {links &&
            links.map((item, index) => (
              <div key={index}>
                {!item.footer_link_type ? (
                  <a href={item.footer_post_link?.footer_post_link_href} className={s.mobileRoute__link}>
                    {item.footer_post_link?.tr_footer_post_link_label}
                  </a>
                ) : (
                  <a href={item.footer_custom_link?.footer_custom_link_href} className={s.mobileRoute__link}>
                    {item.footer_custom_link?.tr_footer_custom_link_label}
                  </a>
                )}
              </div>
            ))}
        </div>
      </div>
    </li>
  );
};
