import s from './Summary.module.scss';
import { useState } from 'react';
import Shield from './Icons/Shield';
import Toggle from '../Toggle/Toggle';
import { useCartSelectors } from '../../../lib/cartState/selectors';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import Timer from './Timer';
import axios from 'axios';
import { formatPrice } from 'utils/helpers';

const Summary = () => {
  const { totalPrice, totalSavings, CartData } = useCartSelectors();
  const { Cart, Theme, Settings } = usewpdataSelectors();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const url = '/wp-json/brands/cart/v1/session';
      const response = await axios.post(
        url,
        { payload: CartData },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        window.location.href = `${Settings.link}/?cart=${response.data.session_hash}`;
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      // isideti sentry exceptiona
      console.log(err);
      setIsLoading(false);
    }
  };
  return (
    <section className={s.summary}>
      <div className={s.heading}>
        <Shield className={s.shield} />
        <p className={s.mainHeading} style={{ color: Cart.styles.colorPrimary }}>
          SHIPPING PROTECTION
        </p>
        <p className={s.secondaryHeading} style={{ color: Cart.styles.colorSecondary }}>
          Protect your order from damage, loss, or theft.
        </p>
        <p className={s.price}>{formatPrice(2.99)}</p>
        <Toggle />
      </div>
      <div className={s.totalSavings}>
        <span>{Cart.savingsLabel}</span>
        <span>-{formatPrice(totalSavings)}</span>
      </div>
      <div className={s.subTotal}>
        <span style={{ color: Cart.styles.colorPrimary }}>{Cart.savingsLabel}</span>
        <span>{formatPrice(totalPrice)}</span>
      </div>
      <button className={`${s.submit}`} data-loading={isLoading} data-theme={Theme.button} onClick={handleSubmit}>
        <span>{Cart.ctaLabel}</span>
        {!isLoading && (
          <>
            <span></span>
            <span>{formatPrice(totalPrice)}</span>
          </>
        )}
      </button>
      <Timer />
    </section>
  );
};

export default Summary;
