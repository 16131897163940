import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './PageRoutes.module.scss';

export const PageRoutes = () => {
  const { footer, Settings } = usewpdataSelectors();
  return (
    <>
      <div className={s.pageRoutes}>
        {footer.links &&
          footer.links.map((column, index) => {
            return (
              <div className={s.pageRoutes__box} key={index}>
                <h3 className={s.pageRoutes__header}>{column.tr_footer_links_column_title}</h3>
                <ul className={s.pageRoutes__list}>
                  {column.footer_links_column_repeater.map((item, index) => {
                    return !item.footer_link_type ? (
                      <li key={index}>
                        <a href={item.footer_post_link?.footer_post_link_href}>
                          {item?.footer_post_link?.tr_footer_post_link_label}
                        </a>
                      </li>
                    ) : (
                      <li key={index}>
                        <a href={item.footer_custom_link?.footer_custom_link_href}>
                          {item?.footer_custom_link?.tr_footer_custom_link_label}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}

        <div className={s.pageRoutes__box}>
          <h3 className={s.pageRoutes__header}>{footer.customerCareLabel}</h3>
          <ul className={s.pageRoutes__list}>
            <li>
              <img src={`${window?.template_root}/images/icons/support.svg`} alt="alt" />
              <a href={`mailto: ${Settings.email}`}>{footer.emailSupportLabel}</a>
            </li>
            <li>
              <img src={`${window?.template_root}/images/icons/email.svg`} alt="alt" />
              <a href={`mailto: ${Settings.email}`}>{footer.emailSupportLabel}</a>
            </li>
            <li>
              <img src={`${window?.template_root}/images/icons/chat.svg`} alt="alt" />
              <a href={`mailto: ${Settings.email}`}>{footer.liveChatLabel}</a>
            </li>
            <li>
              <img src={`${window?.template_root}/images/icons/phone.svg`} alt="alt" />
              <a href={`tel: ${Settings.phone}`}>{Settings.phone}</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
