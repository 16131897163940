import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './UpsellModal.module.scss';
import { useState } from 'react';
import Accordion from 'components/Accordion/Accordion';

const FAQ = () => {
  const { hero } = usewpdataSelectors();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const faqItems = [
    {
      icon: `${window.template_root}/temp_images/productfaq/list.svg`,
      title: 'Details',
      content:
        'Our rings are all handmade and are crafted to perfection with a gorgeous alternative to a mined diamond. You’ll get all of the brilliance of a perfect and colorless diamond without having to spend a fortune. Our stones are cut to the highest quality standards and the color, cut and clarity will amaze you with their brilliant shine.',
    },
    {
      icon: `${window.template_root}/temp_images/productfaq/information.svg`,
      title: 'Description',
      content:
        'Our rings are all handmade and are crafted to perfection with a gorgeous alternative to a mined diamond. You’ll get all of the brilliance of a perfect and colorless diamond without having to spend a fortune. Our stones are cut to the highest quality standards and the color, cut and clarity will amaze you with their brilliant shine.',
    },
    {
      icon: `${window.template_root}/temp_images/productfaq/fabric.svg`,
      title: 'Materials',
      content:
        'Our rings are all handmade and are crafted to perfection with a gorgeous alternative to a mined diamond. You’ll get all of the brilliance of a perfect and colorless diamond without having to spend a fortune. Our stones are cut to the highest quality standards and the color, cut and clarity will amaze you with their brilliant shine.',
    },
    {
      icon: `${window.template_root}/temp_images/productfaq/delivery.svg`,
      title: 'Shipping & Deliveri',
      content:
        'Our rings are all handmade and are crafted to perfection with a gorgeous alternative to a mined diamond. You’ll get all of the brilliance of a perfect and colorless diamond without having to spend a fortune. Our stones are cut to the highest quality standards and the color, cut and clarity will amaze you with their brilliant shine.',
    },
  ];

  return (
    <div className={s.faq}>
      <div className={s.accordionContainer}>
        {/* {hero.faqList &&
          hero.faqList.repeater?.map((faq, index) => (
            <Accordion
              key={index}
              icon={faq.faq_icon}
              index={index}
              title={faq?.tr_faq_heading}
              description={faq?.tr_faq_answer}
              isOpen={index === openIndex}
              toggle={toggleAnswer}
              size="large"
              customStyles={s}
            />
          ))} */}
        {faqItems &&
          faqItems?.map((faq, index) => (
            <Accordion
              key={index}
              icon={faq.icon}
              index={index}
              title={faq.title}
              description={faq.content}
              isOpen={index === openIndex}
              toggle={toggleAnswer}
              size="large"
              customStyles={s}
            />
          ))}
      </div>
    </div>
  );
};

export default FAQ;
