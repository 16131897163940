import React from 'react';
import { SetupCardProps } from 'lib/wpdataState/wpDataType';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

const SetupCard: React.FC<SetupCardProps> = ({ step_image, step_icon, tr_step_title, tr_step_description, index }) => {
  const { Setup } = usewpdataSelectors();
  return (
    <div className="easySteps__stepContainer">
      <img className="easySteps__stepImage mon-img-700-w" src={step_image} alt="alt" />
      <div className="easySteps__stepContent">
        <img className="easySteps__stepIcon" alt="alt" src={step_icon} />
        <h3 className="easySteps__stepHeading" style={{ color: Setup.styles.textColorPrimary }}>
          {tr_step_title}
        </h3>
        <p className="easySteps__stepDescription" style={{ color: Setup.styles.textColorSecondary }}>
          {tr_step_description}
        </p>
      </div>
    </div>
  );
};

export default SetupCard;
