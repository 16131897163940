import s from '../ImageSlider.module.scss';
type Props = {
  onClick?: () => void;
  arrowsInMobile?: boolean;
};

const SliderNextIcon = ({ onClick, arrowsInMobile = false }: Props) => (
  <svg
    className={arrowsInMobile ? s.imageSlider__nextSlide : `${s.imageSlider__displayNone} ${s.imageSlider__nextSlide}`}
    onClick={onClick}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="6.4" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 32 32)" fill="#F6F6F9" />
    <path
      d="M13.3333 9.33342L20 16.0001L13.3333 22.6667"
      stroke="#B3C1E5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SliderNextIcon;
