interface PlusInCircleProps {
  className?: string;
}
const PlusInCircle = ({ className }: PlusInCircleProps) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6810_21989)">
      <path
        d="M8 0C3.58853 0 0 3.58853 0 8C0 12.4115 3.58853 16 8 16C12.4115 16 16 12.4108 16 8C16 3.58916 12.4115 0 8 0ZM8 14.7607C4.27266 14.7607 1.23934 11.728 1.23934 8C1.23934 4.27203 4.27266 1.23934 8 1.23934C11.7273 1.23934 14.7607 4.27203 14.7607 8C14.7607 11.728 11.728 14.7607 8 14.7607Z"
        fill="#6B5F5F"
      />
      <path
        d="M11.0984 7.32445H8.61973V4.84576C8.61973 4.5037 8.34273 4.22607 8.00004 4.22607C7.65736 4.22607 7.38036 4.5037 7.38036 4.84576V7.32445H4.90167C4.55898 7.32445 4.28198 7.60207 4.28198 7.94414C4.28198 8.2862 4.55898 8.56382 4.90167 8.56382H7.38036V11.0425C7.38036 11.3846 7.65736 11.6622 8.00004 11.6622C8.34273 11.6622 8.61973 11.3846 8.61973 11.0425V8.56382H11.0984C11.4411 8.56382 11.7181 8.2862 11.7181 7.94414C11.7181 7.60207 11.4411 7.32445 11.0984 7.32445Z"
        fill="#6B5F5F"
      />
    </g>
    <defs>
      <clipPath id="clip0_6810_21989">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PlusInCircle;
