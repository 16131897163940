import s from './ProductCard.module.scss';
import RemoveIcon from '../Icons/RemoveIcon';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { formatPrice } from 'utils/helpers';
import { cartProductItem } from 'lib/cartState/types';
import { useCartActions } from 'lib/cartState/actions';
import { useCartSelectors } from 'lib/cartState/selectors';
import { useState } from 'react';

type props = {
  cartItem: cartProductItem['product'];
  type: 'main' | 'main-variants' | 'upsell';
  quantity?: number;
  variant?: any;
  sizeLabel?: string;
  colorLabel?: string;
};

const ProductCard = ({ cartItem, type, quantity, variant, sizeLabel, colorLabel }: props) => {
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const { removeUpsellFromCartAction, adjustUpsellQuantityAction, adjustMainProductQuantityAction } = useCartActions();
  const { Settings, hero, Cart, Theme } = usewpdataSelectors();
  const { cartProducts } = useCartSelectors();

  // add bundle items price to main product
  const bundleProps = cartProducts.mainProduct.bundle;
  const bundleItemsPrice = bundleProps ? bundleProps.reduce((acc, item) => acc + Number(item.price), 0) : 0;
  const bundleItemsOldPrice = bundleProps
    ? bundleProps.reduce((acc, item) => (acc + Number(item.old_price) ? Number(item.old_price) : 0), 0)
    : 0;

  const increaseQuantity = () => {
    if (type === 'upsell') {
      adjustUpsellQuantityAction(cartItem.sku, Number(cartItem.amount) + 1);
    } else {
      adjustMainProductQuantityAction(Number(cartItem.amount) + 1);
    }
  };

  const decreaseQuantity = () => {
    if (cartItem.amount > 1) {
      if (type === 'upsell') {
        adjustUpsellQuantityAction(cartItem.sku, Number(cartItem.amount) - 1);
      } else {
        adjustMainProductQuantityAction(Number(cartItem.amount) - 1);
      }
    }
  };

  let productImage =
    type === 'main'
      ? cartItem?.image?.url
      : type === 'main-variants'
      ? typeof variant?.variant_image === 'string' && variant.variant_image.length > 1
        ? variant.variant_image
        : cartItem?.image?.url
      : cartItem?.product_image;

  const title = window.productData.show_bundle_design ? hero.title : cartItem.tr_display_name ?? cartItem.display_name;
  const description =
    type === 'main-variants'
      ? [colorLabel, sizeLabel].filter(Boolean).join(' | ')
      : cartItem.generic_name ?? cartItem.tr_display_name;

  return (
    <>
      <div className={s.productCard}>
        <div className={s.quantityWrapper}>
          <div className={s.imageWrapper}>
            <div className={s.sumOfItem} style={{ background: Cart.styles.colorPrimary }}>
              {quantity ?? cartItem.amount}x
            </div>
            <div className={s.image}>
              <img className={s.productImage} src={productImage} alt={cartItem.tr_display_name} />
            </div>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.upper}>
            <div className={s.titles}>
              <div className={s.title} data-theme={Theme.heading} style={{ color: Cart.styles.colorPrimary }}>
                {title}
              </div>
              <div className={s.description} style={{ color: Cart.styles.colorSecondary }}>
                {description}
              </div>
              {Settings.bundleMode &&
                bundleProps.map((bundleProp, index) => {
                  return (
                    <div key={index} className={s.description} style={{ color: Cart.styles.colorSecondary }}>
                      {bundleProp.label}
                    </div>
                  );
                })}
            </div>
            <div
              className={s.remove}
              onClick={() => {
                removeUpsellFromCartAction(cartItem.sku);
              }}
            >
              {type === 'upsell' && <RemoveIcon />}
            </div>
          </div>
          <div className={s.lower}>
            <div className={s.prices}>
              <div className={s.price} style={{ color: Cart.styles.colorPrimary }}>
                {cartItem.price && formatPrice(Number(cartItem.price) + bundleItemsPrice)}
              </div>
              <div className={s.oldPrice} style={{ color: Cart.styles.colorSecondary }}>
                {cartItem.old_price && formatPrice(Number(cartItem.old_price) + bundleItemsOldPrice)}
              </div>
            </div>

            <div className={s.quantity}>
              <button className={s.decrease} onClick={decreaseQuantity}>
                <span style={{ backgroundColor: cartItem.amount > 1 ? '#404040' : '#E5E5E5' }}></span>
              </button>
              <div className={s.number}>{cartItem.amount}</div>
              <button className={s.increase} onClick={increaseQuantity}>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
