type Props = {
  className?: string;
};

export const Amex = ({ className = '' }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="36" height="25" viewBox="0 0 36 25" fill="none">
    <rect x="0.28125" width="34.9363" height="24.094" rx="2.4094" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.95071 8.53418L3.77539 15.8093H7.57669L8.04794 14.6493H9.12512L9.59637 15.8093H13.7805V14.924L14.1534 15.8093H16.3178L16.6906 14.9053V15.8093H25.3925L26.4506 14.6795L27.4414 15.8093L31.9109 15.8187L28.7255 12.1921L31.9109 8.53418H27.5107L26.4807 9.64313L25.5211 8.53418H16.0546L15.2417 10.412L14.4097 8.53418H10.6163V9.38937L10.1944 8.53418H6.95071ZM19.9453 9.56712H24.9424L26.4707 11.2764L28.0484 9.56712H29.5767L27.2545 12.1909L29.5767 14.7844H27.979L26.4507 13.0553L24.865 14.7844H19.9453V9.56712ZM21.1794 11.601V10.648V10.6471H24.2974L25.6579 12.1712L24.2371 13.7036H21.1794V12.6632H23.9055V11.601H21.1794ZM7.68626 9.56712H9.53919L11.6454 14.5004V9.56712H13.6752L15.302 13.1043L16.8012 9.56712H18.8209V14.7875H17.592L17.582 10.6968L15.7903 14.7875H14.691L12.8893 10.6968V14.7875H10.3611L9.88182 13.6172H7.29237L6.81407 14.7865H5.4595L7.68626 9.56712ZM7.73478 12.5355L8.5879 10.4506L9.44003 12.5355H7.73478Z"
      fill="#1F72CD"
    />
  </svg>
);
