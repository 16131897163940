type Props = {
  className?: string;
};

const Shield = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="encrypted (1) 1">
        <g id="Group">
          <path
            id="Vector"
            d="M22.6532 3.61936V11.3259C22.6532 13.2333 21.7789 15.4473 20.1921 17.5601C18.3429 20.0216 15.6188 22.1943 12.3145 23.8434L12.0004 24L11.6863 23.8434C8.38199 22.1943 5.65798 20.0216 3.80869 17.5601C2.22191 15.4473 1.34766 13.2333 1.34766 11.3259V3.61936L6.53036 0H17.4705L22.6532 3.61936Z"
            fill="#404040"
          />
          <path
            id="Vector_2"
            d="M22.6528 3.61936V11.3259C22.6528 13.2333 21.7785 15.4473 20.1917 17.5601C18.3424 20.0216 15.6184 22.1943 12.3141 23.8434L12 24V0H17.4701L22.6528 3.61936Z"
            fill="#404040"
          />
          <path
            id="Vector_3"
            d="M16.5853 2.8125H7.41528L4.16016 5.08556V11.3258C4.16016 13.4704 6.27008 17.6612 12.0003 20.8315C17.7305 17.6612 19.8405 13.4704 19.8405 11.3258V5.08556L16.5853 2.8125Z"
            fill="#404040"
          />
          <path
            id="Vector_4"
            d="M19.8401 5.08556V11.3258C19.8401 13.4704 17.7302 17.6612 12 20.8315V2.8125H16.585L19.8401 5.08556Z"
            fill="#404040"
          />
          <path
            id="Vector_5"
            d="M16.8066 2.10938H7.19405L3.45703 4.71901V11.3258C3.45703 12.6974 4.15175 14.4403 5.36257 16.1073C6.83685 18.137 9.01428 19.9835 11.66 21.447L12.0003 21.6355L12.3407 21.447C14.9864 19.9835 17.1638 18.137 18.6381 16.1073C19.8489 14.4403 20.5436 12.6974 20.5436 11.3258V4.71901L16.8066 2.10938ZM19.1373 11.3258C19.1373 13.1967 17.229 17.0167 12.0003 20.0243C6.77169 17.0167 4.86334 13.1967 4.86334 11.3258V5.45216L7.63657 3.51568H16.3641L19.1373 5.45216V11.3258Z"
            fill="white"
          />
          <path
            id="Vector_6"
            d="M20.5433 4.71901V11.3258C20.5433 12.6974 19.8486 14.4403 18.6378 16.1073C17.1635 18.137 14.9861 19.9835 12.3403 21.447L12 21.6355V20.0243C17.2286 17.0167 19.137 13.1967 19.137 11.3258V5.45216L16.3638 3.51568H12V2.10938H16.8063L20.5433 4.71901Z"
            fill="white"
          />
          <path
            id="Vector_7"
            d="M16.4896 8.16816L12.0006 12.7818L11.9913 12.7916L10.8498 13.9645L7.51172 10.8425L8.47223 9.81542L10.8029 11.9957L11.9913 10.775L12.0006 10.7651L15.4817 7.1875L16.4896 8.16816Z"
            fill="white"
          />
          <path id="Vector_8" d="M16.4889 8.16816L12 12.7818V10.7651L15.4811 7.1875L16.4889 8.16816Z" fill="white" />
        </g>
      </g>
    </svg>
  );
};

export default Shield;
