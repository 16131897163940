import { TypedUseSelectorHook, useSelector } from 'react-redux';
import type { RootState } from '../store';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useproductsdataSelectors = () => {
  const appSelector = useAppSelector;

  return {
    Products: appSelector((state: RootState) => state.productsdata.Products),
    ProductVariants: appSelector((state: RootState) => state.productsdata.Products?.variants || []),
    EnableSizeVariants: appSelector((state: RootState) => state.productsdata.EnableSizeVariants),
    EnableColorVariants: appSelector((state: RootState) => state.productsdata.EnableColorVariants),
    ProductColorVariants: appSelector((state: RootState) => state.productsdata.ProductColorVariants),
    ProductSizeVariants: appSelector((state: RootState) => state.productsdata.ProductSizeVariants),
    upsells: appSelector((state: RootState) => state.productsdata.Upsells),
    pickerUpsells: appSelector((state: RootState) => state.productsdata.PickerUpsells),
  };
};
