type Props = {
  className?: string;
};

export const Mastercard = ({ className = '' }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
    <path d="M18.9822 2.91466L11.2051 2.94141L11.4407 17.1793L19.2179 17.1526L18.9822 2.91466Z" fill="#FF5F00" />
    <path
      d="M11.7172 10.0881C11.6691 7.19221 12.9663 4.6191 14.9982 2.95007C13.4645 1.74654 11.5417 1.02281 9.45736 1.02995C4.51943 1.04686 0.591787 5.11478 0.675062 10.1259C0.758337 15.137 4.82072 19.1776 9.75866 19.1607C11.843 19.1535 13.7415 18.4167 15.2351 17.2028C13.1483 15.5731 11.7653 12.984 11.7172 10.0881Z"
      fill="#EB001B"
    />
    <path
      d="M29.7475 9.96707C29.8308 14.9782 25.9032 19.0461 20.9652 19.063C18.8809 19.0702 16.9581 18.3464 15.4243 17.1429C17.4811 15.4738 18.7535 12.9008 18.7054 10.0049C18.6573 7.10901 17.2747 4.54508 15.1875 2.89015C16.681 1.67626 18.5796 0.939444 20.6639 0.932305C25.6019 0.915393 29.6647 4.98112 29.7475 9.96707Z"
      fill="#F79E1B"
    />
  </svg>
);
