export const XIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Component 16">
      <path
        id="Vector"
        d="M18.8748 3.87549L16.1248 1.12549L9.99975 7.37549L3.87476 1.12549L1.12476 3.87549L7.37476 10.0005L1.12476 16.1255L3.87476 18.8755L9.99975 12.6255L16.1248 18.8755L18.8748 16.1255L12.6248 10.0005L18.8748 3.87549Z"
        fill="#EA9393"
      />
    </g>
  </svg>
);
