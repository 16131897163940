import { Component } from 'react';
import Slider from 'react-slick';
import s from './UpsellModal.module.scss';
import { useCartSelectors } from 'lib/cartState/selectors';

const swiperSettings = {
  focusOnSelect: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  className: s.productGallery__innerDiv,
  variableWidth: true,
  dots: false,
  adaptiveHeight: true,
  rows: 1,
  nextArrow: '',
  prevArrow: '',
  arrows: false,
};

const SwiperGallery = () => {
  const { UpsellModal: UpsellModalData } = useCartSelectors();

  const gallery = [
    UpsellModalData?.product_image,
    UpsellModalData?.product_image,
    UpsellModalData?.product_image,
    UpsellModalData?.product_image,
    UpsellModalData?.product_image,
    UpsellModalData?.product_image,
  ];
  return (
    <div className={s.productGallery}>
      <Slider {...swiperSettings}>
        {gallery.map((item) => (
          <div className={s.productGallery__item}>
            <img src={item} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SwiperGallery;
