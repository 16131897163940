import { NextArrowSvg } from '../icons/NextArrowSvg';
import { PaddingSvg } from '../icons/PaddingSvg';
import { PrevArrowSvg } from '../icons/PrevArrowSvg';

export const getReviewCardSliderSettings = (currentSlide: number, setCurrentSlide: (index: number) => void) => ({
  dots: true,
  dotsClass: 'custom-dots',
  customPaging: function (i: number) {
    const isActive = i === currentSlide;
    const fill = isActive ? '#B3C1E5' : '#FFF';
    const stroke = isActive ? '' : '#EDEDF3';
    return (
      <div onClick={() => setCurrentSlide(i)}>
        <PaddingSvg fill={fill} stroke={stroke} />
      </div>
    );
  },
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  speed: 3000,
  autoplaySpeed: 3000,
  cssEase: 'linear',
  className: 'center',
  arrows: true,
  nextArrow: <NextArrowSvg className="custom-next-arrow" />,
  prevArrow: <PrevArrowSvg className="custom-prev-arrow" />,
  beforeChange: (_: unknown, next: number) => setCurrentSlide(next),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

export const testimonialsSliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 200,
  autoplaySpeed: 4000,
  cssEase: 'linear',
  className: 'center',
  responsive: [
    {
      breakpoint: 769,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: false,
        centerPadding: '30px',
        infinite: true,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        className: 'center',
      },
    },
    {
      breakpoint: 550,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: false,
        centerPadding: '30px',
        infinite: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        className: 'center',
      },
    },
    {
      breakpoint: 500,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: true,
        centerPadding: '55px',
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: true,
        centerPadding: '40px',
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 370,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: true,
        centerPadding: '30px',
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const reviewSwiperSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 200,
  autoplaySpeed: 4000000,
  cssEase: 'linear',
  className: 'center',
  responsive: [
    {
      breakpoint: 769,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: false,
        centerPadding: '30px',
        infinite: true,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        className: 'center',
      },
    },
    {
      breakpoint: 550,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: false,
        centerPadding: '30px',
        infinite: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        className: 'center',
      },
    },
    {
      breakpoint: 500,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: true,
        centerPadding: '55px',
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: true,
        centerPadding: '40px',
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 370,
      settings: {
        speed: 1000,
        autoplaySpeed: 0,
        centerMode: true,
        centerPadding: '30px',
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
