import React from 'react';

type VideoPlayerProps = {
  video_id: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ video_id }) => {
  return (
    <div className="featuresList-card__image">
      <div className="featuresList-card__videoWrapper">
        <div className="featuresList-card__video">
          <iframe
            title="title"
            id="video_player"
            className="content__image-video"
            src={`https://iframe.videodelivery.net/${video_id}/iframe?controls=false&muted=true&autoplay=true&loop=true&preload=true`}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              transform: 'scale(1.02)',
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
