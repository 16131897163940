type Props = {
  className?: string;
};

export const DinersClub = ({ className = '' }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19" fill="none">
    <path
      d="M23.6692 9.12553C23.6692 3.73553 19.1704 0.0100929 14.241 0.0117194H9.99902C5.01093 0.00993025 0.905273 3.73667 0.905273 9.12553C0.905273 14.0555 5.01104 18.106 9.99902 18.0821H14.241C19.17 18.1058 23.6692 14.0542 23.6692 9.12553Z"
      fill="#0079BE"
    />
    <path
      d="M10.0253 0.774414C5.46738 0.775824 1.77409 4.47784 1.77246 9.04678C1.7736 13.615 5.46705 17.3164 10.0253 17.3181C14.5843 17.3167 18.2787 13.615 18.2792 9.04678C18.2786 4.47773 14.5844 0.776041 10.0253 0.774414Z"
      fill="white"
    />
    <path
      d="M4.80859 9.02586C4.81293 6.79322 6.20451 4.88935 8.16677 4.13281V13.9178C6.20451 13.1617 4.81288 11.2588 4.80859 9.02591V9.02586ZM11.911 13.9203V4.13308C13.874 4.88783 15.2678 6.79279 15.2713 9.02645C15.2677 11.2608 13.874 13.1644 11.911 13.9205V13.9203Z"
      fill="#0079BE"
    />
  </svg>
);
