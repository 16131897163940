import { Component } from 'react';
import Slider from 'react-slick';
import s from './UpsellModal.module.scss';

const swiperSettings = {
  focusOnSelect: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  //   className: s.productGallery__innerDiv,
  variableWidth: true,
  adaptiveHeight: true,
  className: 'center',
  centerMode: true,
  rows: 1,
};

const UserGallery = () => {
  const gallery = [
    `${window.template_root}/temp_images/testimonials/1.jpg`,
    `${window.template_root}/temp_images/testimonials/2.jpg`,
    `${window.template_root}/temp_images/testimonials/3.jpg`,
    `${window.template_root}/temp_images/testimonials/4.jpg`,
    `${window.template_root}/temp_images/testimonials/1.jpg`,
    `${window.template_root}/temp_images/testimonials/2.jpg`,
  ];
  return (
    <div className={s.userGallery}>
      <Slider {...swiperSettings}>
        {gallery.map((item) => (
          <div className={s.userGallery__item}>
            <img src={item} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default UserGallery;
