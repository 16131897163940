import React, { useEffect, useState } from 'react';
import { Image } from '@mantine/core';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { ReviewModalImage, ReviewModalFormFields, ReviewModalFieldNames } from './ReviewModal';
import CameraIcon from './Icons/CameraIcon';
import CloseIcon from './Icons/CloseIcon';
import s from './ReviewModalInput.module.scss';

type ReviewModalInputImageProps = {
  input: ReviewModalFieldNames;
  label: string;
  values: ReviewModalFormFields;
};

export const ReviewModalInputImage = ({ input, label, values }: ReviewModalInputImageProps) => {
  const [imageFiles, setImageFiles] = useState<ReviewModalImage[]>([]);

  useEffect(() => {
    console.log(imageFiles);
  }, [imageFiles]);

  useEffect(() => {
    console.log('values.images:', values.images);
  }, [values.images]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, arrayHelpers: FieldArrayRenderProps) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files).map((file) => {
        const url = URL.createObjectURL(file);
        return { id: file.name, url };
      });

      setImageFiles((prevState) => [...prevState, ...fileArray]);
      fileArray.forEach((file) => arrayHelpers.push(file));
    }
  };

  const handleImageRemove = (index: number, arrayHelpers: FieldArrayRenderProps) => {
    const updatedFiles = imageFiles.filter((_, i) => i !== index);
    setImageFiles(updatedFiles);
    arrayHelpers.remove(index);
  };

  return (
    <div className={`${s.reviewModalInput__inputBox} ${s[`reviewModalInput__inputBox--${input}`]}`}>
      <label className={s.reviewModalInput__label} htmlFor={input}>
        {label}
      </label>
      <FieldArray name={input}>
        {(arrayHelpers) => (
          <div className={s.reviewModalInput__galleryWrapper}>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              onChange={(event) => handleImageUpload(event, arrayHelpers)}
            />
            <div className={s.reviewModalInput__imagesBox}>
              {values.images.map((image, index) => (
                <div key={image.id} className={s.reviewModalInput__imageWrapper}>
                  <Image
                    src={image.url}
                    className={s.reviewModalInput__image}
                    alt="uploaded image preview"
                    width={50}
                    height={50}
                  />
                  <button
                    type="button"
                    className={s.reviewModalInput__closeButton}
                    onClick={() => handleImageRemove(index, arrayHelpers)}
                  >
                    <CloseIcon />
                  </button>
                </div>
              ))}
            </div>
            <button
              className={s.reviewModalInput__uploadButton}
              style={{
                marginLeft: values?.images?.length > 0 ? '8px' : '0',
                width: imageFiles.length === 0 ? '100%' : '50px',
                padding: imageFiles.length === 0 ? '12px 24px' : '0px',
              }}
              type="button"
              onClick={() => document.getElementById('file-input')?.click()}
            >
              <CameraIcon />
              {imageFiles.length === 0 && <span>Select your image</span>}
            </button>
          </div>
        )}
      </FieldArray>
      <span className={s.reviewModalInput__maxSizeText}>You can uploud maximum 4 photos.</span>
    </div>
  );
};
