type Props = {
  className?: string;
};

export const Visa = ({ className = '' }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="31" height="10" viewBox="0 0 31 10" fill="none">
    <path
      d="M28.1021 0.555664H26.2088C25.6364 0.555664 25.1961 0.73179 24.9319 1.3042L21.3213 9.53807H23.8751C23.8751 9.53807 24.3154 8.43729 24.4035 8.1731C24.6677 8.1731 27.1775 8.1731 27.5297 8.1731C27.6178 8.48132 27.8379 9.49404 27.8379 9.49404H30.1276L28.1021 0.555664ZM25.108 6.32378C25.3281 5.7954 26.0767 3.81399 26.0767 3.81399C26.0767 3.85802 26.2968 3.28561 26.3849 2.97739L26.561 3.76996C26.561 3.76996 27.0454 5.9275 27.1334 6.36781H25.108V6.32378Z"
      fill="#3362AB"
    />
    <path
      d="M21.4981 6.58725C21.4981 8.43657 19.8249 9.66945 17.227 9.66945C16.1262 9.66945 15.0695 9.44929 14.4971 9.1851L14.8493 7.15966L15.1575 7.29175C15.9501 7.644 16.4785 7.7761 17.4472 7.7761C18.1517 7.7761 18.9002 7.51191 18.9002 6.89547C18.9002 6.49919 18.592 6.235 17.6233 5.79468C16.6986 5.35437 15.4658 4.64987 15.4658 3.37296C15.4658 1.6117 17.183 0.422852 19.6047 0.422852C20.5294 0.422852 21.3219 0.598977 21.8063 0.819134L21.454 2.75652L21.2779 2.58039C20.8376 2.40427 20.2652 2.22814 19.4286 2.22814C18.5039 2.27217 18.0636 2.66845 18.0636 3.02071C18.0636 3.41699 18.592 3.72521 19.4286 4.12149C20.8376 4.78196 21.4981 5.5305 21.4981 6.58725Z"
      fill="#3362AB"
    />
    <path
      d="M0.0107422 0.641946L0.0547736 0.46582H3.84147C4.36985 0.46582 4.76613 0.641946 4.89823 1.21435L5.73483 5.17718C4.89823 3.06367 2.96085 1.34645 0.0107422 0.641946Z"
      fill="#F9B50B"
    />
    <path
      d="M11.0628 0.554774L7.23204 9.49315H4.63419L2.43262 2.00781C4.01775 3.02053 5.33869 4.60566 5.82304 5.70645L6.08722 6.63111L8.46492 0.510742H11.0628V0.554774Z"
      fill="#3362AB"
    />
    <path d="M12.0753 0.510742H14.497L12.9559 9.49315H10.5342L12.0753 0.510742Z" fill="#3362AB" />
  </svg>
);
