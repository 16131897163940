'use client';
import { Dispatch, SetStateAction, useState } from 'react';
import Star from './Star';
import s from './StarRating.module.scss';

type StarRatingProps = {
  emptyStarStroke?: string;
  emptyStarFill?: string;
  fullStarStroke?: string;
  fullStarFill?: string;
  errorStarStroke?: string;
  errorStarFill?: string;
  rating: number;
  setRating: Dispatch<SetStateAction<number>>;
  isError: boolean;
};

export default function StarRating({
  emptyStarStroke = '#FFCB13',
  emptyStarFill = '#ffffff',
  fullStarStroke = '#FFCB13',
  fullStarFill = '#FFCB13',
  errorStarStroke = '#E34400',
  errorStarFill = '#ffffff',
  rating,
  setRating,
  isError,
}: StarRatingProps) {
  const [tempRating, setTempRating] = useState(0);

  const handleRating = (newRating: number) => {
    if (rating === newRating) {
      setRating(0);
    } else {
      setRating(newRating);
    }
    setTempRating(0);
  };

  const handleType = (isError: boolean, i: number) => {
    if (isError) {
      return 'error';
    } else if (tempRating ? tempRating >= i + 1 : rating >= i + 1) {
      return 'full';
    } else {
      return 'empty';
    }
  };

  return (
    <div className={s.starContainer}>
      {Array.from({ length: 5 }, (_, i) => (
        <Star
          key={i}
          type={handleType(isError, i)}
          onRate={() => handleRating(i + 1)}
          onHoverIn={() => setTempRating(i + 1)}
          onHoverOut={() => setTempRating(0)}
          emptyStarStroke={emptyStarStroke}
          emptyStarFill={emptyStarFill}
          fullStarStroke={fullStarStroke}
          fullStarFill={fullStarFill}
          errorStarStroke={errorStarStroke}
          errorStarFill={errorStarFill}
        />
      ))}
    </div>
  );
}
