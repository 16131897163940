import { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import s from './UpsellModal.module.scss';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import ReviewCard from 'components/ReviewsSwiper/ReviewsCard/ReviewCard';
import { reviewSwiperSettings } from 'components/ReviewsSwiper/utils/sliderSettings';
export type Testimonial = {
  testimonialMedia: string;
  productImage: string;
  testimonialText: string;
  rating: number;
  author: string;
  videoId: string;
  id: string;
};

const swiperSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 200,
  autoplaySpeed: 4000000,
  cssEase: 'linear',
  className: 'center',
};

const ReviewsSwiper = () => {
  const { hero } = usewpdataSelectors();

  const reviewCardData = [
    {
      title: 'Sed ut perspiciatis unde omnis iste natus error...',
      description:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas',
      username: 'Kristie C.',
    },
    {
      title: 'Sed ut perspiciatis unde omnis iste natus error...',
      description:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas',
      username: 'Kristie C.',
    },
    {
      title: 'Sed ut perspiciatis unde omnis iste natus error...',
      description:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et ',
      username: 'Kristie C.',
    },
    {
      title: 'Sed ut perspiciatis unde omnis iste natus error...',
      description:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas',
      username: 'Kristie C.',
    },
    {
      title: 'Sed ut perspiciatis unde omnis iste natus error...',
      description:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas',
      username: 'Kristie C.',
    },
    {
      title: 'Sed ut perspiciatis unde omnis iste natus error...',
      description:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et ',
      username: 'Kristie C.',
    },
  ];

  const [playingCardId, setPlayingCardId] = useState<string | null>(null);

  return (
    <div className={s.testimonialsContainer}>
      <div className={s.testimonialsContainer__wrapper}>
        <section className={`${s.testimonialsContainer__sliderWrapper}`}>
          <Slider
            {...swiperSettings}
            appendDots={(dots) => (
              <div>
                <ul className={s.dots}> {dots} </ul>
              </div>
            )}
            afterChange={() => setPlayingCardId(null)}
          >
            {/* {hero.testimonials &&
              hero.testimonials.map((card, index) => (
                <ReviewCard
                  key={index}
                  tr_title={card.tr_title}
                  tr_description={card.tr_description}
                  user_name={card.user_name}
                />
              ))} */}
            {reviewCardData &&
              reviewCardData.map((card, index) => (
                <ReviewCard
                  key={index}
                  tr_title={card.title}
                  tr_description={card.description}
                  user_name={card.username}
                />
              ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};
export default ReviewsSwiper;
