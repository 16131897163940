import StarRating from 'components/Hero/icons/Stars';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './UpsellModal.module.scss';
import { useCartSelectors } from 'lib/cartState/selectors';

const TitleBlock = () => {
  const { hero, Theme } = usewpdataSelectors();
  const { UpsellModal: UpsellModalData } = useCartSelectors();
  return (
    <div className={s.titleBlock}>
      <div className={s.ratingBlock}>
        <StarRating rating={hero.star_rating.rating} className={s.ratingStars} />
        <p className={s.ratingText}>
          {hero.star_rating.rating_text} <strong>{hero.star_rating.rating}</strong>
        </p>
        <p className={s.reviewsText}>{hero.star_rating.ratings_count_text}</p>
      </div>
      <h1 className={s.title} data-theme={Theme.heading} style={{ color: Theme.headingColor }}>
        {UpsellModalData?.tr_display_name}
      </h1>
    </div>
  );
};

export default TitleBlock;
