import { upsellItem, upsellVariantItem } from 'lib/productsState/productsTypes';
import s from './Upsells.module.scss';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import StarRating from 'components/Hero/icons/Stars';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useCartActions } from 'lib/cartState/actions';
import UpsellVariantPicker from './UpsellVariantPicker';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCartSelectors } from 'lib/cartState/selectors';

const UpsellCard = ({ upsell }: { upsell: upsellItem['product'] }) => {
  const { Settings, Cart, Theme } = usewpdataSelectors();
  const { setUpsellModalAction, addUpsellToCartAction } = useCartActions();

  console.log(upsell);
  // State for selected variants specific to each upsell
  const [selectedColorVariant, setSelectedColorVariant] = useState<upsellVariantItem | undefined>();
  const [selectedSizeVariant, setSelectedSizeVariant] = useState<upsellVariantItem | undefined>();
  useEffect(() => {
    if (upsell.color_variants && upsell.color_variants.length > 0) {
      setSelectedColorVariant(upsell.color_variants[0]);
    }
    if (upsell.size_variants && upsell.size_variants.length > 0) {
      setSelectedSizeVariant(upsell.size_variants[0]);
    }
  }, [upsell]);

  const handleAddToCart = () => {
    addUpsellToCartAction({
      product: { ...upsell, id: uuidv4() },
      variant: [selectedColorVariant, selectedSizeVariant],
    });
  };

  return (
    <div className={s.upsellCard}>
      <div className={s.imgWrapper}>
        <img src={upsell.product_image} alt={upsell.tr_display_name} />
        <div className={s.badge} style={{ background: Cart.styles.badgeColor }}>
          <p>{Cart.bestSellerLabel}</p>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.upper}>
          <div className={s.title} data-theme={Theme.heading} style={{ color: Cart.styles.colorPrimary }}>
            {upsell.tr_display_name}
          </div>
          <div className={s.rating}>
            <StarRating rating={5} className={s.stars} />
            <p style={{ color: Cart.styles.colorSecondary }}>1356 Verified Reviews</p>
          </div>
          <div className={s.prices}>
            <div className={s.price} style={{ color: Cart.styles.colorPrimary }}>
              {Settings.formattedString.replace(/(0\.00|0,00|0)/, upsell.price.toString())}
            </div>
            <div className={s.oldPrice} style={{ color: Cart.styles.colorSecondary }}>
              {Settings.formattedString.replace(/(0\.00|0,00|0)/, upsell.old_price.toString())}
            </div>
          </div>
        </div>
        <div className={s.variantPickers}>
          {upsell.color_variants && selectedColorVariant && (
            <UpsellVariantPicker
              variants={upsell.color_variants}
              type="color"
              selectedVariant={selectedColorVariant}
              setVariant={setSelectedColorVariant}
            />
          )}
          {upsell.size_variants && selectedSizeVariant && (
            <UpsellVariantPicker
              variants={upsell.size_variants}
              type="size"
              selectedVariant={selectedSizeVariant}
              setVariant={setSelectedSizeVariant}
            />
          )}
        </div>
        <div className={s.lower}>
          <button className={s.viewBtn} data-theme={Theme.button} onClick={() => setUpsellModalAction(upsell)}>
            {Cart.viewLabel}
          </button>
          <button className={`${s.addBtn} cta`} data-theme={Theme.button} onClick={handleAddToCart}>
            {Cart.addLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

const Upsells = () => {
  const { upsells } = useproductsdataSelectors();
  const { cartUpsells } = useCartSelectors();

  const cartUpsellSkus = cartUpsells && cartUpsells.map((cartUpsell) => cartUpsell.product.sku);

  const filteredUpsells =
    upsells &&
    upsells.filter((upsell) => {
      if (upsell.upsell_type[0] === 'picker') {
        return false;
      }
      if (!upsell.enable_variants) {
        return true;
      }
      return !cartUpsellSkus.includes(upsell.sku);
    });
  return (
    <>
      {filteredUpsells && filteredUpsells.length > 0 && (
        <div className={s.upsells}>
          {filteredUpsells.map((upsell, index) => (
            <UpsellCard key={index} upsell={upsell} />
          ))}
        </div>
      )}
    </>
  );
};

export default Upsells;
