type StarsProps = {
  className?: string;
  color?: string;
};
const Stars = ({ className, color = '#FF9213' }: StarsProps) => {
  return (
    <svg
      className={className}
      width="97"
      height="16"
      viewBox="0 0 97 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0754 5.71885L11.1567 4.96639L8.95208 0.263392C8.78741 -0.0877974 8.21208 -0.0877974 8.04741 0.263392L5.84342 4.96639L0.924765 5.71885C0.520766 5.78094 0.359433 6.27301 0.642766 6.56344L4.21609 10.2316L3.37143 15.4173C3.30409 15.8292 3.74342 16.1384 4.10676 15.9361L8.50008 13.5044L12.8934 15.9367C13.2534 16.137 13.6967 15.8339 13.6287 15.418L12.7841 10.2322L16.3574 6.56411C16.6407 6.27301 16.4787 5.78094 16.0754 5.71885Z"
        fill={color}
      />
      <path
        d="M36.0754 5.71885L31.1567 4.96639L28.9521 0.263392C28.7874 -0.0877974 28.2121 -0.0877974 28.0474 0.263392L25.8434 4.96639L20.9248 5.71885C20.5208 5.78094 20.3594 6.27301 20.6428 6.56344L24.2161 10.2316L23.3714 15.4173C23.3041 15.8292 23.7434 16.1384 24.1068 15.9361L28.5001 13.5044L32.8934 15.9367C33.2534 16.137 33.6967 15.8339 33.6287 15.418L32.7841 10.2322L36.3574 6.56411C36.6407 6.27301 36.4787 5.78094 36.0754 5.71885Z"
        fill={color}
      />
      <path
        d="M56.0754 5.71885L51.1567 4.96639L48.9521 0.263392C48.7874 -0.0877974 48.2121 -0.0877974 48.0474 0.263392L45.8434 4.96639L40.9248 5.71885C40.5208 5.78094 40.3594 6.27301 40.6428 6.56344L44.2161 10.2316L43.3714 15.4173C43.3041 15.8292 43.7434 16.1384 44.1068 15.9361L48.5001 13.5044L52.8934 15.9367C53.2534 16.137 53.6967 15.8339 53.6287 15.418L52.7841 10.2322L56.3574 6.56411C56.6407 6.27301 56.4787 5.78094 56.0754 5.71885Z"
        fill={color}
      />
      <path
        d="M76.0754 5.71885L71.1567 4.96639L68.9521 0.263392C68.7874 -0.0877974 68.2121 -0.0877974 68.0474 0.263392L65.8434 4.96639L60.9248 5.71885C60.5208 5.78094 60.3594 6.27301 60.6428 6.56344L64.2161 10.2316L63.3714 15.4173C63.3041 15.8292 63.7434 16.1384 64.1068 15.9361L68.5001 13.5044L72.8934 15.9367C73.2534 16.137 73.6967 15.8339 73.6287 15.418L72.7841 10.2322L76.3574 6.56411C76.6407 6.27301 76.4787 5.78094 76.0754 5.71885Z"
        fill={color}
      />
      <path
        d="M96.0754 5.71885L91.1567 4.96639L88.9521 0.263392C88.7874 -0.0877974 88.2121 -0.0877974 88.0474 0.263392L85.8434 4.96639L80.9248 5.71885C80.5208 5.78094 80.3594 6.27301 80.6428 6.56344L84.2161 10.2316L83.3714 15.4173C83.3041 15.8292 83.7434 16.1384 84.1068 15.9361L88.5001 13.5044L92.8934 15.9367C93.2534 16.137 93.6967 15.8339 93.6287 15.418L92.7841 10.2322L96.3574 6.56411C96.6407 6.27301 96.4787 5.78094 96.0754 5.71885Z"
        fill={color}
      />
    </svg>
  );
};

export default Stars;
