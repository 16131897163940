import React from 'react';
import { TestimonialCardProps } from '../../lib/wpdataState/wpDataType';

const TestimonialCard: React.FC<TestimonialCardProps> = ({ tr_heading, tr_description, user_name, score }) => {
  return (
    <div className="testimonial-card" style={{ height: '100%' }}>
      <div className="testimonial-card__wrapper">
        <img className="testimonial-card__image" src={`${window.template_root}/starsBasic/${score}.svg`} alt="alt" />
        <h5 className="testimonial-text--heading">{tr_heading}</h5>
        <div className="testimonial-text--description">
          <div dangerouslySetInnerHTML={{ __html: tr_description }} />
        </div>
        <div className="testimonial-card__bottom">
          <p className="testimonial-text--userName">{user_name}</p>
          <div className="testimonial-card__verified">
            <img src={`${window.template_root}/images/icons/verified.svg`} alt={`verified icon`} />
            <span className="testimonial-text--accent">verified</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
