import React from 'react';
import { featureInnerListItem } from '../../lib/wpdataState/wpDataType';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

type FeaturesListInnerCardProps = {
  innerList: featureInnerListItem[];
};

const FeaturesListInner: React.FC<FeaturesListInnerCardProps> = ({ innerList }) => {
  const { FeaturesList } = usewpdataSelectors();

  return (
    <ul className="featuresList-innerList">
      {innerList.map((item: featureInnerListItem, index: number) => (
        <li className="featuresList-innerCard" key={index}>
          <div className="featuresList-innerCard__content">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C9.05058 16 10.0909 15.7931 11.0615 15.391C12.0321 14.989 12.914 14.3997 13.6569 13.6569C14.3997 12.914 14.989 12.0321 15.391 11.0615C15.7931 10.0909 16 9.05058 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 -1.56548e-08 8 0C5.87827 3.16163e-08 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM7.79378 11.2356L12.2382 5.90222L10.8729 4.76444L7.05067 9.35022L5.07289 7.37156L3.816 8.62844L6.48267 11.2951L7.17067 11.9831L7.79378 11.2356Z"
                fill={FeaturesList.styles.checkboxColor}
              />
            </svg>
            <p
              className="featuresList-text--innerCardDescription"
              style={{ color: FeaturesList.styles.textColorSecondary }}
            >
              {item.tr_innerList_description}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FeaturesListInner;
