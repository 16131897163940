import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import s from './ImageSlider.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderPrevIcon from './Icons/SliderPrevIcon';
import SliderNextIcon from './Icons/SliderNextIcon';
import ReviewsSwiper from 'components/ReviewsSwiper/ReviewsSwiper';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import DesktopNav from './DesktopNav';

type SliderType = {
  tablet?: boolean;
  mobile?: boolean;
  dotsInMobile?: boolean;
  isPagingImages?: boolean;
  arrowsInMobile?: boolean;
  arrayLength: number;
  version: 'version-1' | 'version-2';
};

const ImageSlider = ({ tablet = true, mobile = true, arrowsInMobile = false, version }: SliderType) => {
  const [mainSlider, setMainSlider] = useState(null);
  const [thumbSlider, setThumbSlider] = useState(null);
  const slider = useRef(null);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
    prevArrow: <SliderPrevIcon arrowsInMobile={arrowsInMobile} />,
    nextArrow: <SliderNextIcon arrowsInMobile={arrowsInMobile} />,
    asNavFor: thumbSlider,
  };

  const thumbnailSettings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: mainSlider,
    swipeToSlide: true,
    focusOnSelect: true,
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 5,
        },
      },
    ],
  };

  const { hero } = usewpdataSelectors();

  return (
    <section
      className={`${s.imageSlider} ${mobile ? '' : s['imageSlider--tablet']} ${
        tablet ? '' : s['imageSlider--desktop']
      } ${version === 'version-1' ? s['imageSlider--version-1'] : s['imageSlider--version-2']}`}
    >
      {hero.gallery && (
        <div style={{ position: 'relative' }}>
          <DesktopNav mainSlider={mainSlider} />
          <Slider
            {...settings}
            ref={slider}
            ref={(slider) => setMainSlider(slider)}
            className={s.imageSlider__container}
          >
            {hero.gallery.map((item, index) => (
              <img key={index} src={item.gallery_image} alt="alt" />
            ))}
          </Slider>
        </div>
      )}

      {hero.gallery && (
        <Slider {...thumbnailSettings} className={s.thumb__slider} ref={(slider) => setThumbSlider(slider)}>
          {hero.gallery.map((item, index) => (
            <img src={item.gallery_image} alt="thumbnail" style={{ marginRight: '8px' }} key={index} />
          ))}
        </Slider>
      )}

      <ReviewsSwiper />
    </section>
  );
};

export default ImageSlider;
