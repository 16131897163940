import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initializeVariantPickerState from '../../utils/defaultVariants';
import { FetchedProductType } from 'utils/types/productTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { cartProductItem } from './types';
import { productType, upsellItem, VariantType } from 'lib/productsState/productsTypes';

type CartState = {
  isCartOpen: boolean;
  finalPrice: number;
  cartProducts: {
    mainProduct: {
      product: productType;
      variants: VariantType[];
      bundle: any[];
    };
    upsells: upsellItem[];
  };

  productsQuantity: number;
  withShippingProtection: boolean;
  totalSavings: number;
  selectedProduct: FetchedProductType | null;
  selectedProductIsSubscribed: boolean;
  selectedProductFrequency: number;
  selectedProductQuantity: number;
  hasFreeGift: boolean;
  hasFreeShipping: boolean;
  freeGiftPrice: number;
  freeShippingPrice: number;
  upsellModal: any;
};

const initialState: CartState = {
  upsellModal: undefined,
  isCartOpen: false,
  finalPrice: 0,
  totalSavings: 0,
  cartProducts: {
    mainProduct: {
      product: {} as any,
      variants: [],
      bundle: [],
    },
    upsells: [],
  },
  productsQuantity: 0,
  withShippingProtection: true,
  selectedProduct: null,
  selectedProductIsSubscribed: true,
  selectedProductFrequency: 1,
  selectedProductQuantity: 1,
  hasFreeGift: false,
  hasFreeShipping: false,
  freeShippingPrice: 200,
  freeGiftPrice: 100,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    toggleCart: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },
    setUpsellModal: (state, action: PayloadAction<upsellItem | undefined>) => {
      state.upsellModal = action.payload;
    },
    addUpsellToCart: (state, action: PayloadAction<cartProductItem>) => {
      if (action.payload) {
        const sameSKUProducts = state.cartProducts.upsells.filter(
          (upsell) => upsell.product.sku === action.payload.product.sku
        );
        const matchingProduct = sameSKUProducts.find((upsell) => {
          const upsellVariants = upsell.variant || [];
          const actionVariants = action.payload.variant || [];
          if (upsellVariants.length !== actionVariants.length) {
            return false;
          }
          return upsellVariants.every((variant, index) => {
            const actionVariant = actionVariants[index];
            if (!variant && !actionVariant) {
              return true;
            }
            if (!variant || !actionVariant) {
              return false;
            }
            return variant.tr_label === actionVariant.tr_label;
          });
        });
        if (matchingProduct) {
          matchingProduct.product.amount++;
        } else {
          state.cartProducts.upsells = [...(state.cartProducts.upsells || []), action.payload];
        }
      }
    },

    adjustUpsellQuantity: (state, action: PayloadAction<{ id: string; newValue: number }>) => {
      const upsellProduct = state.cartProducts.upsells.find((upsell) => upsell.product.id === action.payload.id);
      if (upsellProduct) {
        upsellProduct.product.amount = action.payload.newValue;
      }
    },
    adjustMainProductQty: (state, action: PayloadAction<{ newValue: number }>) => {
      if (state.cartProducts?.mainProduct?.product) {
        state.cartProducts.mainProduct.product.amount = action.payload.newValue;
      }
    },
    toggleProtection: (state) => {
      state.withShippingProtection = !state.withShippingProtection;
    }, // *
    setTotalSavings: (state) => {
      let savings = 0;
      state.cartProducts?.map((product: any) => (savings += product.savings || 0));
      state.totalSavings = savings || 0;
    },
    setFinalPrice: (state, action: PayloadAction<{ total: number; savings: number }>) => {
      state.finalPrice = action.payload.total;
      state.totalSavings = action.payload.savings;
    }, // *
    setProductsQuantity: (state) => {
      let quantity = 0;
      state.cartProducts.upsells?.map((product) => (quantity += Number(product.product.amount)));
      quantity += Number(state.cartProducts.mainProduct.product.amount);
      state.productsQuantity = quantity;
    },
    setSavingsQuantityPrice: (state) => {
      cartSlice.caseReducers.setTotalSavings(state);
      cartSlice.caseReducers.setFinalPrice(state);
      cartSlice.caseReducers.setProductsQuantity(state);
    },
    setCartProducts: (state, action: PayloadAction<any[]>) => {
      state.cartProducts = action.payload;
    },
    addCartProduct: (state, action: PayloadAction<any>) => {
      state.cartProducts?.push(action.payload);
    },
    updateCartProduct: (state: CartState, action: PayloadAction<{ id: number; changes: Partial<any> }>) => {
      const index = state.cartProducts?.findIndex((product: any) => product.data.id === action.payload.id);
      if (index !== -1 && index !== undefined && state.cartProducts !== undefined) {
        state.cartProducts[index] = {
          ...state.cartProducts[index],
          ...action.payload.changes,
        };
      }
    },
    deleteCartProduct: (state, action: PayloadAction<number>) => {
      state.cartProducts = state.cartProducts?.filter((product: any) => product.data.id !== action.payload);
    },
    setSelectedProduct: (state, action: PayloadAction<{ product: ProductType }>) => {
      if (state.cartProducts && state.cartProducts.mainProduct) {
        state.cartProducts.mainProduct.product = { ...action.payload };
      }
    },
    setSelectedProductIsSubscribed: (state, action: PayloadAction<boolean>) => {
      state.selectedProductIsSubscribed = action.payload;
    },
    setSelectedProductFrequency: (state, action: PayloadAction<number>) => {
      state.selectedProductFrequency = action.payload;
    },
    setSelectedProductQuantity: (state, action: PayloadAction<number>) => {
      state.selectedProductQuantity = action.payload;
    },
    deleteSelectedProduct: (state) => {
      state.selectedProduct = null;
    },
    setDefaultVariants: (state, action: PayloadAction<{ colorVariants: any; sizeVariants: any; maxAmount: any }>) => {
      const initialVariants = initializeVariantPickerState(
        action.payload.colorVariants,
        action.payload.sizeVariants,
        action.payload.maxAmount
      );
      if (!state.cartProducts) return;
      state.cartProducts.mainProduct.variants = initialVariants;
    },
    changeVariantColorState: (
      state,
      action: PayloadAction<{ type: string; value: string; id: string; label: string; img: string }>
    ) => {
      state.cartProducts?.mainProduct?.variants.forEach((item: variantObjectType, index: number) => {
        if (item.id === action.payload.id && state.cartProducts) {
          state.cartProducts.mainProduct.variants[index] = {
            ...item,
            colorValue: action.payload.value,
            colorLabel: action.payload.label,
            colorImage: action.payload.img,
          };
        }
      });
    },
    setSelectedUpsell: (state, action: PayloadAction<{ upsellItem: any; upsellId: string }>) => {
      state.cartProducts = {
        ...state.cartProducts,
        upsells: [action.payload],
      };
    },
    changeVariantSizeState: (
      state,
      action: PayloadAction<{ type: string; value: string; id: string; label: string }>
    ) => {
      state.cartProducts?.mainProduct?.variants.forEach((item: variantObjectType, index: number) => {
        if (item.id === action.payload.id && state.cartProducts) {
          state.cartProducts.mainProduct.variants[index] = {
            ...item,
            sizeValue: action.payload.value,
            sizeLabel: action.payload.label,
          };
        }
      });
    },
    setUpsells: (state, action: PayloadAction<any[]>) => {
      state.cartProducts = {
        ...state.cartProducts,
        upsells: action.payload,
      };
    },
    setDefaultBundle: (
      state,
      action: PayloadAction<{
        value: string;
        id: string;
        parent_id: string;
        label: string;
        price: number;
        old_price: number;
        konnektive_id: string;
      }>
    ) => {
      const bundlePropertyExists = state.cartProducts?.mainProduct?.bundle.some((item) => {
        return item.id === action.payload.id;
      });
      if (!bundlePropertyExists) {
        return {
          ...state,
          cartProducts: {
            ...state.cartProducts,
            mainProduct: {
              ...state?.cartProducts?.mainProduct,
              bundle: [
                ...state?.cartProducts?.mainProduct?.bundle,
                {
                  parent_id: action.payload.parent_id,
                  sku: action.payload.sku,
                  label: action.payload.label,
                  id: action.payload.id,
                  price: action.payload.price,
                  old_price: action.payload.old_price,
                  konnektive_id: action.payload.konnektive_id,
                },
              ],
            },
          },
        };
      }
    },
    setBundleProduct: (
      state,
      action: PayloadAction<{
        sku: string;
        id: string;
        parent_id: string;
        label: string;
        price: number;
        old_price: number;
        konnektive_id: string;
      }>
    ) => {
      const filteredBundle = state?.cartProducts?.mainProduct?.bundle?.filter(
        (item) => item.parent_id !== action.payload.parent_id
      );
      const updatedBundleItem = {
        sku: action.payload.sku,
        label: action.payload.label,
        id: action.payload.id,
        parent_id: action.payload.parent_id,
        price: action.payload.price,
        old_price: action.payload.old_price,
        konnektive_id: action.payload.id,
      };

      return {
        ...state,
        cartProducts: {
          ...state.cartProducts,
          mainProduct: {
            ...state?.cartProducts?.mainProduct,
            bundle: [...filteredBundle, updatedBundleItem],
          },
        },
      };
    },
  },
});

export const {
  toggleCart,
  setFinalPrice,
  setUpsellModal,
  addUpsellToCart,
  setCartProducts,
  addCartProduct,
  setProductsQuantity,
  toggleProtection,
  deleteCartProduct,
  setTotalSavings,
  setSelectedProductIsSubscribed,
  setSelectedProductFrequency,
  setSelectedProductQuantity,
  deleteSelectedProduct,
  setDefaultVariants,
  setSavingsQuantityPrice,
  changeVariantColorState,
  changeVariantSizeState,
  setSelectedUpsell,
  setSelectedProduct,
  setUpsells,
  adjustMainProductQty,
  setDefaultBundle,
  setBundleProduct,
  adjustUpsellQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
