import React, { useState, useEffect, useRef } from 'react';
import VariantOption from './VariantOption';
import { colorVariant } from 'lib/productsState/productsTypes';
import './variantsStyles.scss';
import { useCartActions } from 'lib/cartState/actions';
import { decode } from 'html-entities';

type VariantSelectProps = {
  data: colorVariant;
  id: string;
  size: number;
  type: string;
  defaultVariant: any;
};

const VariantSelect = ({ data, id, size, type, defaultVariant }: VariantSelectProps) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const { changeVariantSize, changeVariantColor } = useCartActions();
  const label = type === 'color' ? defaultVariant.colorLabel : defaultVariant.sizeLabel;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current) {
        const target = event.target as Node;
        if (!selectRef.current.contains(target)) {
          setOpen(false);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={selectRef}
      className={`${
        open ? 'variant-select--active variant-select' : 'variant-select--passive variant-select'
      } variant-select--${size}`}
      onClick={() => {
        setOpen(!open);
      }}
      aria-expanded={open}
      aria-haspopup="true"
    >
      <div className="variant-select--label">
        <p dangerouslySetInnerHTML={{ __html: decode(label) }}></p>
        <div className="variant-select--chevron" data-expanded={open}>
          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.99999 5.5C4.84317 5.5 4.68637 5.43998 4.56681 5.32018L0.804499 1.5486C0.565167 1.30868 0.565167 0.919689 0.804499 0.679867C1.04373 0.440044 1.43169 0.440044 1.67104 0.679867L4.99999 4.01717L8.32895 0.679983C8.56828 0.440161 8.9562 0.440161 9.19541 0.679983C9.43486 0.919806 9.43486 1.30879 9.19541 1.54871L5.43316 5.3203C5.31354 5.44011 5.15675 5.5 4.99999 5.5Z"
              fill="#828282"
            />
          </svg>
        </div>
      </div>
      <div className="custom-select"></div>
      {open && (
        <div className="custom-select--hidden">
          <div className="custom-select__wrapper">
            <ul className="custom-select__inner">
              {data &&
                data.map((item: colorVariant, index: number) => {
                  const img = item.variant_image;
                  return (
                    <VariantOption
                      key={index}
                      label={item?.tr_additional_label ? item?.tr_additional_label : item?.tr_label}
                      color={item?.color_hex}
                      type={type}
                      id={item.id}
                      parentId={id}
                      value={item?.value}
                      currentSelect={defaultVariant}
                      handleClick={(type: string, value: string, label: string) => {
                        type === 'color'
                          ? changeVariantColor(type, value, label, defaultVariant.id, img)
                          : changeVariantSize(type, value, label, defaultVariant.id);
                      }}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default VariantSelect;
