import { useEffect, useState } from 'react';
import { Image } from '@mantine/core';
import s from './IgVideos.module.scss';
import VideoModal from 'components/Modals/VideoModal/VideoModal';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

type BenefitProps = {
  showMobileDesktop?: boolean;
  showTablet?: boolean;
};

const igVideosData = [
  {
    id: 'a01',
    imageUrl: `${window.template_root}/temp_images/igvideos/1.jpg`,
    videoId: '1c45782271809a5c267d02cc94442dc9',
    imageAlt: 'person-using-product',
    name: 'jazzyjay',
  },
  {
    id: 'a02',
    imageUrl: `${window.template_root}/temp_images/igvideos/2.png`,
    videoId: '4f077a19597b18ab030f0686c0d34911',
    imageAlt: 'person-using-product',
    name: 'samsparks',
  },
  {
    id: 'a03',
    imageUrl: `${window.template_root}/temp_images/igvideos/3.png`,
    videoId: '1c45782271809a5c267d02cc94442dc9',
    imageAlt: 'person-using-product',
    name: 'bellabee',
  },
  {
    id: 'a04',
    imageUrl: `${window.template_root}/temp_images/igvideos/4.png`,
    videoId: '4f077a19597b18ab030f0686c0d34911',
    imageAlt: 'person-using-product',
    name: 'maxmuse',
  },
  {
    id: 'a05',
    imageUrl: `${window.template_root}/temp_images/igvideos/5.png`,
    videoId: '1c45782271809a5c267d02cc94442dc9',
    imageAlt: 'person-using-product',
    name: 'leoluxe',
  },
  {
    id: 'a06',
    imageUrl: `${window.template_root}/temp_images/igvideos/6.png`,
    videoId: '1c45782271809a5c267d02cc94442dc9',
    imageAlt: 'person-using-product',
    name: 'ninanotes',
  },
];

const IgVideos = ({ showMobileDesktop = true, showTablet = true }: BenefitProps) => {
  const [styleClass, setStyleClass] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const { hero } = usewpdataSelectors();

  const handleNext = () => setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % igVideosData.length);
  const handlePrevious = () =>
    setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + igVideosData.length) % igVideosData.length);

  const openModal = (index: number) => {
    setCurrentVideoIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentVideoIndex(0);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!showMobileDesktop) {
      setStyleClass(s['heroBenefits--showMobileDesktop']);
    }
    if (!showTablet) {
      setStyleClass(s['heroBenefits--showTablet']);
    }
  }, [showMobileDesktop, showTablet]);
  return (
    <section className={`${s.igVideos} ${styleClass}`}>
      <div className={s.igVideos__imageWrapper}>
        {hero.instaBubbles &&
          hero.instaBubbles.map((item, index) => (
            <div key={index} onClick={() => openModal(index)}>
              <Image className={s.igVideos__image} width={64} height={64} src={item.thumbnail_image} alt="alt" />
              <p className={s.igVideos__name}>{name}</p>
            </div>
          ))}
      </div>
      {hero.instaBubbles && (
        <VideoModal
          isOpen={isModalOpen}
          onClose={closeModal}
          videoIds={hero.instaBubbles}
          videoIndex={currentVideoIndex}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      )}
    </section>
  );
};

export default IgVideos;
