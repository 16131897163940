import React from 'react';
import '../../ProductHero/QuantityCards/QuantityCards.module.scss';
import { upsellVariantItem } from 'lib/productsState/productsTypes';

type VariantOptionProps = {
  variant: upsellVariantItem;
  type: 'color' | 'size';
  currentSelect: upsellVariantItem;
  handleClick: EventListener;
};

const UpsellVariantPickerOption = ({ variant, type, currentSelect, handleClick }: VariantOptionProps) => {
  return (
    <li
      className={`custom-select--item ${currentSelect.tr_label === variant.tr_label && 'custom-select--item--active'}`}
      onClick={() => {
        handleClick(variant);
      }}
    >
      {type == 'color' && (
        <div
          className="custom-select__colorIndicator"
          style={{ background: type === 'color' ? variant.color : '' }}
        ></div>
      )}
      <span>{variant.tr_label}</span>
    </li>
  );
};

export default UpsellVariantPickerOption;
