export const ShieldIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="charm:shield-tick">
      <g id="Group">
        <path
          id="Vector"
          d="M10.5 1.07129L18 3.92843V11.0713C18 14.2856 15.1429 17.4999 10.5 18.9284C5.85714 17.4999 3 14.6427 3 11.0713V3.92843L10.5 1.07129Z"
          stroke="#6B5F5F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.28516 9.6423L9.42801 11.7852L13.7137 6.78516"
          stroke="#6B5F5F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
);
