import { useState } from 'react';
import { Stream } from '@cloudflare/stream-react';
import s from './TestimonialCard.module.scss';
import { Image } from '@mantine/core';
import { PlayButton } from '../icons/PlayButton';
import StarRating from '../../Hero/icons/Stars';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

type TestimonialCardProps = {
  card_image: string;
  card_productImage: string;
  tr_card_description: string;
  review_rating: number;
  card_author: string;
  video_id: string;
  onPlay: (videoId: string | null) => void;
  isPlaying: boolean;
  verifiedText: string;
};

const TestimonialCard = ({
  card_image,
  card_productImage,
  tr_card_description,
  review_rating,
  card_author,
  video_id,
  onPlay,
  isPlaying,
  verifiedText,
}: TestimonialCardProps) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const { Settings } = usewpdataSelectors();

  const handlePlayPause = () => {
    if (isVideoPlaying) {
      setIsVideoPlaying(false);
      onPlay(null);
    } else {
      onPlay(id);
      setIsVideoPlaying(true);
    }
  };

  const handleVideoPause = () => {
    setIsVideoPlaying(false);
  };

  return (
    <div className={s.testimonialCardContainer}>
      {!isPlaying ? (
        <>
          <div className={s.playButtonWrapper} onClick={handlePlayPause}>
            <PlayButton />
          </div>
          <div className={s.testimonialCardContainer__mediaWrapper}>
            <Image
              src={card_image}
              alt="testimonial media"
              className={s.testimonialCardContainer__testimonialMedia}
              width={288}
              height={360}
            />
          </div>

          <div className={s.testimonialCardContainer__content}>
            <div className={s.wrapper}>
              <Image src={card_productImage} alt="product image" className={s.productImage} width={48} height={90} />
              <div className={s.textWrapper}>
                <p className={s.author}>{card_author}</p>
                <p className={s.author__verified}>
                  <img src={`${Settings.root}/temp_images/verify.svg`} alt={verifiedText} />
                  {verifiedText}
                </p>
                <StarRating rating={review_rating} className={s.testimonialCardContainer__rating} />
              </div>
            </div>
            <StarRating rating={review_rating} className={s.testimonialCardContainer__ratingTablet} />
            <p className={s.text}>“{tr_card_description}”</p>
          </div>
        </>
      ) : (
        <div className={s.testimonialCardContainer__videoContainer} onClick={handlePlayPause}>
          <Stream autoplay muted loop src={video_id} responsive={true} onPause={handleVideoPause} />
        </div>
      )}
    </div>
  );
};

export default TestimonialCard;
