export const CheckIcon = ({ fillColor }: { fillColor: string }) => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="feCheck0">
      <g id="feCheck1">
        <path
          id="feCheck2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.5 5.85693L0 8.35693L7.5 15.8569L20 3.35693L17.5 0.856934L7.5 10.8569L2.5 5.85693Z"
          fill={fillColor}
        />
      </g>
    </g>
  </svg>
);
