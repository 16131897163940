type Props = {
  className?: string;
};

export const CloseIcon = ({ className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.17015 14.2423C6.98261 14.4298 6.72826 14.5352 6.46304 14.5352C6.19783 14.5352 5.94347 14.4298 5.75593 14.2423C5.5684 14.0547 5.46304 13.8004 5.46304 13.5352C5.46304 13.2699 5.5684 13.0156 5.75593 12.828L12.827 5.75698C13.0145 5.56945 13.2689 5.46409 13.5341 5.46409C13.7993 5.46409 14.0537 5.56945 14.2412 5.75698C14.4288 5.94452 14.5341 6.19887 14.5341 6.46409C14.5341 6.7293 14.4288 6.98366 14.2412 7.1712L7.17015 14.2423Z"
        fill="#1C2B54"
      />
      <path
        d="M5.75593 7.17195C5.5684 6.98441 5.46304 6.73006 5.46304 6.46484C5.46304 6.19963 5.5684 5.94527 5.75593 5.75774C5.94347 5.5702 6.19783 5.46484 6.46304 5.46484C6.72826 5.46484 6.98261 5.5702 7.17015 5.75774L14.2412 12.8288C14.4288 13.0163 14.5341 13.2707 14.5341 13.5359C14.5341 13.8011 14.4288 14.0555 14.2412 14.243C14.0537 14.4306 13.7993 14.5359 13.5341 14.5359C13.2689 14.5359 13.0145 14.4306 12.827 14.243L5.75593 7.17195Z"
        fill="#1C2B54"
      />
    </svg>
  );
};
