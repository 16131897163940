import { productType, upsellItem, bundleType } from 'lib/productsState/productsTypes';

export const calculateMainTotals = (product: productType, bundle: bundleType[]) => {
  const bundleItemsPrice = bundle ? bundle.reduce((acc, item) => acc + Number(item?.price), 0) : 0;
  console.log('bundle price:', bundleItemsPrice);
  const bundleItemsOldPrice = bundle ? bundle.reduce((acc, item) => Number(acc) + Number(item?.old_price), 0) : 0;
  const mainProductPrice = Number(product.price) + Number(bundleItemsPrice);
  console.log('bundle OLD PRICE:', bundleItemsOldPrice);
  const mainProductOldPrice = Number(product.old_price) + Number(bundleItemsOldPrice);
  const mainProductAmount = Number(product.amount);

  const mainTotal = mainProductPrice * mainProductAmount;
  const mainSavings = (mainProductOldPrice - mainProductPrice) * mainProductAmount;
  return { mainTotal, mainSavings };
};

export const calculateUpsellsTotals = (upsells: upsellItem[]) => {
  let upsellsTotal = 0;
  let upsellsSavings = 0;

  upsells.forEach((upsell) => {
    const upsellPrice = Number(upsell.product.price);
    const upsellOldPrice = Number(upsell.product.old_price);
    const upsellAmount = Number(upsell.product.amount);

    upsellsTotal += upsellPrice * upsellAmount;
    upsellsSavings += (upsellOldPrice - upsellPrice) * upsellAmount;
  });

  return { upsellsTotal, upsellsSavings };
};

export const consolidateVariants = (
  variants: any[],
  ProductColorVariants: any[] = [],
  ProductSizeVariants: any[] = []
) => {
  const variantMap: { [key: string]: { quantity: number; colorVariant: any; sizeVariant: any } } = {};

  variants.forEach(
    (variant: { colorValue: any; colorLabel: string | number; sizeValue: any; sizeLabel: string | number }) => {
      // Safely use an empty array if ProductColorVariants is undefined
      const colorVariant = (ProductColorVariants || []).find(
        (cvariant: { value: any }) => cvariant.value === variant.colorValue
      );

      // Safely use an empty array if ProductSizeVariants is undefined
      const sizeVariant = (ProductSizeVariants || []).find(
        (svariant: { value: any }) => svariant.value === variant.sizeValue
      );

      // Create a unique key combining both color and size
      const variantKey = `${variant.colorLabel}-${variant.sizeLabel}`;

      // Consolidate by both color and size
      if (variantMap[variantKey]) {
        variantMap[variantKey].quantity += 1;
      } else {
        variantMap[variantKey] = {
          quantity: 1,
          colorVariant,
          sizeVariant,
        };
      }
    }
  );

  // Map the consolidated variants into the desired structure
  return Object.entries(variantMap).map(([key, { quantity, colorVariant, sizeVariant }]) => ({
    colorLabel: colorVariant?.tr_label ? colorVariant?.tr_label || key.split('-')[0] : undefined, // Use color label from color variant or fallback to part of key
    sizeLabel: sizeVariant?.tr_label ? sizeVariant?.tr_label || key.split('-')[1] : undefined, // Use size label from size variant or fallback to part of key
    quantity,
    colorVariant,
    sizeVariant,
  }));
};
