type Props = {
  className?: string;
};

export const PayPal = ({ className = '' }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="31" height="9" viewBox="0 0 31 9" fill="none">
    <path
      d="M5.96812 0.671812C5.59062 0.412298 5.09802 0.282227 4.49032 0.282227H2.13761C1.95131 0.282227 1.84839 0.375398 1.82883 0.561561L0.873117 6.56085C0.863184 6.61977 0.877926 6.67366 0.917208 6.72265C0.956266 6.77173 1.00539 6.79614 1.06422 6.79614H2.18175C2.37775 6.79614 2.48549 6.70319 2.50526 6.5168L2.7699 4.8994C2.77956 4.82102 2.81404 4.75729 2.87287 4.70821C2.93166 4.65922 3.00519 4.62717 3.09342 4.61252C3.18164 4.59796 3.26484 4.59058 3.3434 4.59058C3.42174 4.59058 3.51482 4.59557 3.62283 4.60533C3.73056 4.61508 3.79924 4.61989 3.82868 4.61989C4.67167 4.61989 5.33336 4.38235 5.81378 3.90674C6.29397 3.4314 6.53434 2.77224 6.53434 1.92897C6.53434 1.35062 6.34544 0.931595 5.96812 0.671676V0.671812ZM4.75509 2.47321C4.70592 2.81637 4.57863 3.04173 4.37279 3.1496C4.16689 3.2576 3.87281 3.31135 3.49055 3.31135L3.00528 3.32601L3.25531 1.75261C3.27482 1.64488 3.3385 1.59094 3.44642 1.59094H3.72589C4.11781 1.59094 4.40227 1.64744 4.57872 1.75998C4.75509 1.87279 4.81392 2.1106 4.75509 2.47321V2.47321Z"
      fill="#003087"
    />
    <path
      d="M30.7966 0.282227H29.7086C29.6005 0.282227 29.5368 0.336161 29.5174 0.444075L28.5616 6.56112L28.5469 6.59051C28.5469 6.63977 28.5665 6.68611 28.6058 6.73025C28.6448 6.77429 28.694 6.79636 28.7528 6.79636H29.7234C29.9093 6.79636 30.0123 6.70341 30.0322 6.51703L30.9879 0.502908V0.488301C30.9879 0.350993 30.924 0.282407 30.7966 0.282407V0.282227Z"
      fill="#009CDE"
    />
    <path
      d="M17.6514 2.65019C17.6514 2.60128 17.6317 2.55463 17.5927 2.51063C17.5534 2.46654 17.5092 2.44434 17.4603 2.44434H16.3281C16.2201 2.44434 16.1318 2.4936 16.0633 2.59135L14.5047 4.88531L13.8577 2.67967C13.8085 2.52295 13.7008 2.44434 13.5343 2.44434H12.4313C12.3821 2.44434 12.338 2.46645 12.2991 2.51063C12.2597 2.55463 12.2402 2.60137 12.2402 2.65019C12.2402 2.66992 12.3358 2.959 12.5269 3.51776C12.7181 4.0767 12.9239 4.6796 13.1445 5.32663C13.3651 5.97352 13.4802 6.31681 13.49 6.35569C12.6861 7.45379 12.2844 8.04195 12.2844 8.12024C12.2844 8.2478 12.348 8.31144 12.4755 8.31144H13.6077C13.7155 8.31144 13.8037 8.26254 13.8725 8.16447L17.6222 2.75306C17.6416 2.73365 17.6514 2.69949 17.6514 2.65014V2.65019Z"
      fill="#003087"
    />
    <path
      d="M28.1939 2.44375H27.0764C26.939 2.44375 26.856 2.60555 26.8265 2.92911C26.5714 2.5371 26.106 2.34082 25.4295 2.34082C24.7237 2.34082 24.1232 2.60555 23.6283 3.13492C23.1332 3.66428 22.8857 4.28696 22.8857 5.00249C22.8857 5.58098 23.0548 6.04167 23.3929 6.38465C23.7312 6.72799 24.1845 6.89932 24.7532 6.89932C25.0375 6.89932 25.3267 6.8404 25.6207 6.72286C25.9148 6.60524 26.145 6.44847 26.3119 6.25233C26.3119 6.26217 26.3019 6.30613 26.2825 6.38452C26.2627 6.46308 26.2531 6.52209 26.2531 6.56097C26.2531 6.71801 26.3166 6.79621 26.4442 6.79621H27.4589C27.6449 6.79621 27.7529 6.70327 27.7822 6.51688L28.3852 2.67899C28.3949 2.62011 28.3802 2.56631 28.3411 2.51723C28.3016 2.46833 28.2527 2.44375 28.1939 2.44375V2.44375ZM26.2751 5.34066C26.0251 5.58574 25.7236 5.70822 25.3707 5.70822C25.0863 5.70822 24.8562 5.62993 24.6796 5.47298C24.5031 5.31648 24.4149 5.10078 24.4149 4.82599C24.4149 4.46355 24.5374 4.15693 24.7826 3.90699C25.0273 3.657 25.3315 3.53205 25.6942 3.53205C25.9685 3.53205 26.1964 3.61291 26.378 3.77458C26.5591 3.93638 26.6501 4.1594 26.6501 4.44377C26.65 4.79664 26.525 5.0957 26.2751 5.34066Z"
      fill="#009CDE"
    />
    <path
      d="M11.489 2.44375H10.3715C10.234 2.44375 10.1509 2.60555 10.1214 2.92911C9.85676 2.5371 9.39109 2.34082 8.72451 2.34082C8.01869 2.34082 7.41818 2.60555 6.9232 3.13492C6.42809 3.66428 6.18066 4.28696 6.18066 5.00249C6.18066 5.58098 6.34979 6.04167 6.688 6.38465C7.02622 6.72799 7.47949 6.89932 8.04813 6.89932C8.32248 6.89932 8.60693 6.8404 8.90096 6.72286C9.19504 6.60524 9.43033 6.44847 9.60678 6.25233C9.56745 6.36986 9.54795 6.47283 9.54795 6.56097C9.54795 6.71801 9.61163 6.79621 9.7391 6.79621H10.7537C10.9398 6.79621 11.0478 6.70327 11.0772 6.51688L11.6801 2.67899C11.6897 2.62011 11.6751 2.56631 11.636 2.51723C11.5967 2.46833 11.5478 2.44375 11.489 2.44375V2.44375ZM9.5701 5.34794C9.32008 5.5884 9.0136 5.70822 8.65111 5.70822C8.36666 5.70822 8.13874 5.62993 7.96732 5.47298C7.79567 5.31648 7.70996 5.10078 7.70996 4.82599C7.70996 4.46355 7.83244 4.15693 8.07761 3.90699C8.32252 3.657 8.62644 3.53201 8.98928 3.53201C9.26362 3.53201 9.49154 3.61291 9.67307 3.77458C9.85429 3.93638 9.94508 4.1594 9.94508 4.44377C9.94504 4.80648 9.82009 5.10802 9.5701 5.34794V5.34794Z"
      fill="#003087"
    />
    <path
      d="M22.6723 0.671812C22.2948 0.412298 21.8024 0.282227 21.1945 0.282227H18.8565C18.6604 0.282227 18.5525 0.375398 18.533 0.561561L17.5772 6.56085C17.5673 6.61977 17.582 6.67366 17.6214 6.72265C17.6602 6.77173 17.7095 6.79614 17.7683 6.79614H18.974C19.0916 6.79614 19.17 6.73249 19.2094 6.60503L19.474 4.8994C19.4838 4.82102 19.5181 4.75729 19.577 4.70821C19.6358 4.65922 19.7093 4.62717 19.7976 4.61252C19.8857 4.59796 19.9689 4.59058 20.0475 4.59058C20.1259 4.59058 20.219 4.59557 20.3269 4.60533C20.4347 4.61508 20.5035 4.61989 20.5327 4.61989C21.3759 4.61989 22.0374 4.38235 22.5179 3.90674C22.9982 3.4314 23.2384 2.77224 23.2384 1.92897C23.2384 1.35062 23.0496 0.93155 22.6723 0.671676V0.671812ZM21.1651 3.09072C20.9495 3.23773 20.6259 3.31122 20.1947 3.31122L19.7241 3.32596L19.9741 1.75252C19.9935 1.64483 20.0572 1.59081 20.1652 1.59081H20.4298C20.6454 1.59081 20.817 1.60065 20.9446 1.6202C21.0718 1.63989 21.1945 1.70106 21.3122 1.80394C21.4299 1.90691 21.4886 2.05648 21.4886 2.25244C21.4886 2.66432 21.3807 2.94357 21.1651 3.09072Z"
      fill="#009CDE"
    />
  </svg>
);
