import React from 'react';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import classes from './comparisonSwiper.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import './beforeAfter.scss';
import ComparisonCard from './ComparisonCard';
import { Carousel } from '@mantine/carousel';
import '@mantine/carousel/styles.css';

const Comparison = () => {
  const { BaComparison } = usewpdataSelectors();
  const theme = useMantineTheme();
  const mobile: boolean | undefined = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <section style={{ background: BaComparison.styles.background }}>
      <div className="testimonials--container">
        <SectionTitle heading="Satisfaction Guaranteed With DashOne Pro" type="section" />
        <Carousel
          classNames={classes}
          slideSize={mobile ? '72%' : '25%'}
          align={mobile ? 'center' : 'start'}
          initialSlide={mobile ? 2 : 0}
          slideGap="md"
        >
          {BaComparison.list &&
            BaComparison.list.map((item, index) => {
              return (
                <Carousel.Slide key={index}>
                  <ComparisonCard item={item} primaryColor={BaComparison.styles.textColorPrimary} />
                </Carousel.Slide>
              );
            })}
        </Carousel>
      </div>
    </section>
  );
};

export default Comparison;
